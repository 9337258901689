import { PuntajeDesafiosModule, PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, ModelViewModule, TranslationsModule } from "@puntaje/shared/core"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { routing } from "./alumnos_desafios.routing"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { NebuModule } from "@puntaje/nebulosa/api-services"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        AlumnosLayoutsModule,
        SharedModule,
        routing,
        Angular2FontawesomeModule,
        UtilModule,
        BannersModule,
        NebuModule,
        TranslationsModule,
        PuntajeDesafiosModule,
        PuntajeEnsayosModule
    ],
    declarations: [],
    exports: []
})
export class AlumnosDesafiosModule {}
