<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div id="section1">
            <div class="caja caja-inicio">
                <div class="fading-image deco" [lazyLoad]="landingImage" [offset]="200"></div>
                <div class="caja-inner-margin">
                    <div class="country-container">
                        <a class="outside" href="https://www.puntajenacional.cl">
                            <span class="pais chile"></span>
                            Chile
                        </a>
                        <a href="http://www.puntajenacional.co/">
                            <span class="pais colombia"></span>
                            Colombia
                        </a>
                    </div>
                    <div class="row">
                        <div class="col-md-8 col-md-offset-2">
                            <div class="titulo">
                                <img
                                    alt="prelog/logo"
                                    src="{{ config.app.assets.logoLargeLight }}"
                                    class="landing-logo"
                                />
                                <h1 *ngIf="titulo">{{ titulo }}</h1>
                                <h2 class="gratis">¡Gratis!</h2>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="inicio-btns">
                                        <a class="btn btn-register" routerLink="/usuarios/registro">
                                            ¿Eres nuev@? ¡Regístrate aquí!
                                        </a>
                                        <!-- <a class="btn hollow-btn" href="https://docentes.puntajenacional.cl/">
                                            <fa name="graduation-cap" class="icono"></fa>
                                            Soy docente
                                        </a> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12" anim-enter-from-right>
                                    <login-puntaje
                                        [disableFormatRut]="true"
                                        [enableContactUs]="false"
                                        [enableRegister]="false"
                                        [enableFacebookLogin]="false"
                                        [enablePasswordRecovery]="true"
                                    ></login-puntaje>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 class="sliding-title more-info">
                        <a
                            href="#section2"
                            easyScrollTo
                            [easyScrollToPadding]="0"
                            [easyScrollToDuration]="800"
                            [easyScrollToEasing]="'easeInOutExpo'"
                        >
                            Conoce más sobre nosotros
                            <span class="flecha-interactiva">
                                <fa name="arrow-down" class="icono"></fa>
                            </span>
                        </a>
                    </h2>
                </div>
            </div>
        </div>
        <sticky-top
            [enableStickyWhiteBackground]="false"
            [topPadding]="0"
            [zIndex]="1000"
            [width]="'100%'"
            class="sticky-nav"
        >
            <nav class="navbar clearfix">
                <div class="navbar-header">
                    <div class="navbar-left navbar-logo-container">
                        <a
                            href="#section1"
                            easyScrollTo
                            [easyScrollToPadding]="0"
                            [easyScrollToDuration]="800"
                            [easyScrollToEasing]="'easeInOutExpo'"
                        >
                            <img
                                class="navbar-logo"
                                src="{{ config.app.assets.logoMedium }}"
                                alt="{{ config.plataforma.info.companyName }}"
                            />
                        </a>
                    </div>
                    <div class="toggle-wrap">
                        <button
                            type="button"
                            class="navbar-toggle"
                            data-toggle="collapse"
                            data-target=".navbar-collapse"
                        >
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                    </div>
                </div>
                <div class="menu-right navbar-collapse collapse">
                    <ul class="nav navbar-nav">
                        <li>
                            <a
                                href="#section2"
                                easyScrollTo
                                [easyScrollToPadding]="0"
                                [easyScrollToDuration]="800"
                                [easyScrollToEasing]="'easeInOutExpo'"
                            >
                                Cómo estudiar
                            </a>
                        </li>
                        <li>
                            <a
                                href="#section3"
                                easyScrollTo
                                [easyScrollToPadding]="0"
                                [easyScrollToDuration]="800"
                                [easyScrollToEasing]="'easeInOutExpo'"
                            >
                                Quiénes somos
                            </a>
                        </li>
                        <li>
                            <a
                                href="#section4"
                                easyScrollTo
                                [easyScrollToPadding]="0"
                                [easyScrollToDuration]="800"
                                [easyScrollToEasing]="'easeInOutExpo'"
                            >
                                Blog
                            </a>
                        </li>
                        <li>
                            <a
                                href="#section5"
                                easyScrollTo
                                [easyScrollToPadding]="0"
                                [easyScrollToDuration]="800"
                                [easyScrollToEasing]="'easeInOutExpo'"
                            >
                                Docentes
                            </a>
                        </li>
                        <!--<li>
							<a href="#section3"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">Testimonios</a>
						</li>-->
                        <li>
                            <a
                                href="#section6"
                                easyScrollTo
                                [easyScrollToPadding]="0"
                                [easyScrollToDuration]="800"
                                [easyScrollToEasing]="'easeInOutExpo'"
                            >
                                ¡Contáctanos!
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </sticky-top>
        <!-- Como estudiar -->
        <div class="caja seccion_como_estudiar" id="section2">
            <div class="caja-inner-margin">
                <h2 class="sliding-title">
                    <span>
                        ¿Cómo estudiar?
                        <ogr-icon name="libro2-o" class="icono"></ogr-icon>
                    </span>
                </h2>
                <div class="inner-wrap">
                    <div class="info-row">
                        <div class="info-col">
                            <h2 class="left-title">
                                Te ayudamos a preparar el examen Saber 11 del Icfes, de forma online, fácil y efectiva.
                                <br />
                                <b>¿Cómo?</b>
                            </h2>
                        </div>
                        <div class="info-col">
                            <div class="info-item-wrap" anim-on-scroll-enter-from-left>
                                <div class="img-wrap">
                                    <img
                                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/ejercita_con_ensayos.webp"
                                        alt="Ejercita con ensayos"
                                    />
                                </div>
                                <div class="item">
                                    <h3>
                                        <ogr-icon name="prueba" class="icono"></ogr-icon>
                                        Ejercita con simulacros
                                    </h3>
                                    <p>
                                        Realiza todos los ensayos que quieras, personalizables por materia y tiempo.
                                        Obtén los resultados instantáneamente.
                                    </p>
                                </div>
                            </div>
                            <div class="info-item-wrap" anim-enter-from-right>
                                <div class="img-wrap">
                                    <img
                                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/planes_de_estudio.webp"
                                        alt="Planes de estudio"
                                    />
                                </div>
                                <div class="item">
                                    <h3>
                                        <ogr-icon name="plan-estudio" class="icono"></ogr-icon>
                                        Planes de estudio
                                    </h3>
                                    <p>
                                        Estudia de forma didáctica para el Saber 11. Sigue los planes de estudio que
                                        contienen test de diagnóstico, guías, videos y mini pruebas.
                                    </p>
                                </div>
                            </div>
                            <div class="info-item-wrap" anim-on-scroll-enter-from-left>
                                <div class="img-wrap">
                                    <img
                                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/clases_en_vivo.webp"
                                        alt="Clases en vivo"
                                    />
                                </div>
                                <div class="item">
                                    <h3>
                                        <ogr-icon name="camara-video-o" class="icono"></ogr-icon>
                                        Clases en vivo
                                    </h3>
                                    <p>Mira las clases en vivo todas las semanas y descarga las guías.</p>
                                </div>
                            </div>
                            <div class="info-item-wrap" anim-on-scroll-enter-from-right>
                                <div class="img-wrap">
                                    <img
                                        src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/repasa_para_la_psu.webp"
                                        alt="Repasa para el Saber11"
                                    />
                                </div>
                                <div class="item">
                                    <h3>
                                        <ogr-icon name="libro2-o" class="icono"></ogr-icon>
                                        Repasa para el Saber 11
                                    </h3>
                                    <p>
                                        Encuentra guías y videos con material para el examen del Icfes, resuelve dudas
                                        con la comunidad y participa en los simulacros mensuales y súmate al ranking.
                                        Todo lo que necesites para que des un excelente Saber 11.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="franja">
            <div class="badge-deco" anim-on-scroll-enter-from-top>
                <ogr-icon name="pro" class="icono"></ogr-icon>
            </div>
            <h2 anim-on-scroll-fade-in>
                Durante 4 años más de 40 mil estudiantes se han preparado en Puntaje Nacional Colombia para las pruebas
                del Icfes. Seguiremos trabajando para reducir la brecha educacional, social y económica del país.
            </h2>
            <div class="badge-deco" anim-on-scroll-enter-from-top>
                <ogr-icon name="pro" class="icono"></ogr-icon>
            </div>
        </div>
        <!-- Quienes somos -->
        <div class="caja seccion_quienes_somos" id="section3">
            <div class="caja-inner-margin">
                <h2 class="sliding-title">
                    <span>
                        Quiénes somos
                        <ogr-icon name="comunidad-o" class="icono"></ogr-icon>
                    </span>
                </h2>
                <div class="inner-wrap">
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="left-title">
                                Con presencia en Colombia desde el 2016, Puntaje Nacional Colombia es el primer
                                preuniversitario online 100% gratuito. Nuestra meta ha sido reducir la brecha
                                educacional, social y económica en la región, ofreciendo clases y simulacros online,
                                material de estudio y aplicaciones educativas.
                            </h3>
                        </div>
                        <div class="col-md-6" anim-on-scroll-enter-from-top>
                            <h3 class="right-title">
                                ¡Ayúdanos a que todos nos conozcan!
                                <br />
                                ¡Síguenos en nuestras redes sociales!
                            </h3>
                            <div class="socialMedia">
                                <a
                                    class="instagram"
                                    href="{{ config.plataforma.info.social.instagram }}"
                                    target="_blank"
                                    title="Instagram"
                                >
                                    <fa name="instagram"></fa>
                                </a>
                                <a
                                    class="youtube"
                                    href="{{ config.plataforma.info.social.youtube }}"
                                    target="_blank"
                                    title="Youtube"
                                >
                                    <fa name="youtube"></fa>
                                </a>
                                <a
                                    class="twitch"
                                    href="{{ config.plataforma.info.social.twitch }}"
                                    target="_blank"
                                    title="Twitch"
                                >
                                    <fa name="twitch"></fa>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="momentos" anim-on-scroll-fade-in>
                        <div class="momento momento1">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/momento1.webp"
                                alt="momento"
                            />
                            <p>Nuestro botón plateado</p>
                        </div>
                        <div class="momento momento2">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/momento2.webp"
                                alt="momento"
                            />
                            <p>Puntaje 2012</p>
                        </div>
                        <div class="momento momento3">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/momento3.webp"
                                alt="momento"
                            />
                            <p>Puntaje 2015</p>
                        </div>
                        <div class="momento momento4">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/momento4.webp"
                                alt="momento"
                            />
                            <p>Puntaje 2018</p>
                        </div>
                        <div class="momento momento5">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/momento5.webp"
                                alt="momento"
                            />
                            <p>Awards 2014</p>
                        </div>
                        <div class="momento momento6">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/momento6.webp"
                                alt="momento"
                            />
                            <p>Awards 2015</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Blog -->

        <div class="caja seccion_blog" id="section4">
            <div class="caja-inner-margin">
                <h2 class="sliding-title">
                    <span>
                        Blog
                        <ogr-icon name="megafono-o" class="icono"></ogr-icon>
                    </span>
                </h2>
                <div class="inner-wrap">
                    <blog-landing [postPerfiles]="['alumnos']"></blog-landing>
                </div>
            </div>
        </div>

        <!-- Profesores -->
        <div class="caja seccion_profesores" id="section5">
            <div class="caja-inner-margin extra-bottom-margin">
                <h2 class="titulo-seccion">
                    <ogr-icon name="establecimiento-o" class="icono"></ogr-icon>
                    <span class="texto">
                        ¿Cómo funciona {{ config.plataforma.info.companyName }} para
                        <em>Docentes</em>
                        ?
                    </span>
                </h2>
                <div class="info-item-col-wrap">
                    <div class="info-item">
                        <span class="info-number">1</span>
                        <div class="info-img">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/docen_01.webp"
                                alt="Contratar los servicios"
                            />
                        </div>
                        <p>
                            Los colegios que contratan los servicios de
                            <em>{{ config.plataforma.info.companyName }}</em>
                            , podrán trabajar a través de nuestra plataforma de manera ilimitada, con la base de datos
                            de sus estudiantes de 10° y 11°, y de profesores de Lenguaje, Matemática, Historia y
                            Ciencias (Biología, Química y Física).
                        </p>
                        <p>
                            Si desea conocer los productos y tarifas, puede contactarnos enviando un correo a
                            <a href="mailto:ventas@ogr.cl">ventas@ogr.cl</a>
                            o a través de nuestra
                            <em>mensajería en la sección de "Contáctanos!".</em>
                        </p>
                    </div>
                    <div class="info-item">
                        <span class="info-number">2</span>
                        <div class="info-img">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/docen_02.webp"
                                alt="Comparte evaluaciones personalizadas"
                            />
                        </div>
                        <p>
                            Los docentes podrán
                            <em>compartir con sus estudiantes Simulacros Saber 11</em>
                            personalizadas a través de nuestra base de datos de preguntas, permitiéndoles editar los
                            contenidos a evaluar, los tiempos en responder, y la cantidad de preguntas.
                        </p>
                    </div>
                    <div class="info-item">
                        <span class="info-number">3</span>
                        <div class="info-img">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/docen_03.webp"
                                alt="Evaluaciones presenciales"
                            />
                        </div>
                        <p>
                            Nuestras evaluaciones también pueden ser realizadas de manera presencial:
                            <em>todas las evaluaciones pueden ser impresas por el colegio</em>
                            .
                        </p>
                        <p>
                            Además, a través de nuestras hojas de respuesta, podrán cargar los resultados de sus
                            estudiantes directamente a la plataforma utilizando una fotografía y siguiendo simples
                            pasos.
                        </p>
                    </div>
                    <div class="info-item">
                        <span class="info-number">4</span>
                        <div class="info-img">
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/landing2019/webp/docen_04.webp"
                                alt="Estadísticas e informes semestrales"
                            />
                        </div>
                        <p>
                            Los docentes podrán monitorear los resultados de sus estudiantes a través de diferentes
                            estadísticas, las cuales ayudarán a identificar sus fortalezas y debilidades. Así mismo
                            podrán usar esta información para crear estrategias de mejora.
                        </p>
                        <p>
                            Los colegios también reciben
                            <em>informes semestrales</em>
                            con las estadísticas de sus estudiantes, contarán con un
                            <em>monitor especialista en {{ config.plataforma.info.companyName }}</em>
                            que prestará apoyo y soporte en cuanto al uso de la plataforma.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contacto -->
        <div class="caja seccion_contacto">
            <div class="wave"></div>
            <div class="caja-inner-margin" id="section6">
                <h2 class="sliding-title">
                    <span>
                        ¡Contáctanos!
                        <fa name="envelope-o" class="icono"></fa>
                    </span>
                </h2>
                <div class="contact-wrapper">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="big-contact-container" anim-on-scroll-enter-from-bottom>
                                <h3>Envía tu mensaje</h3>
                                <puntajeco-contact-form [enableRut]="true"></puntajeco-contact-form>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-container" anim-on-scroll-enter-from-bottom>
                                <div class="speech-pos-1">
                                    <div class="speech-bubble-1">
                                        <p>Encuéntranos también en</p>
                                        <div class="socialMedia">
                                            <div class="t_row">
                                                <div class="t_cell">
                                                    <a
                                                        href="{{ config.plataforma.info.social.facebook }}"
                                                        target="_blank"
                                                        title="Facebook"
                                                    >
                                                        <fa name="facebook"></fa>
                                                    </a>
                                                </div>
                                                <div class="t_cell">
                                                    <a
                                                        href="{{ config.plataforma.info.social.instagram }}"
                                                        target="_blank"
                                                        title="Instagram"
                                                    >
                                                        <fa name="instagram"></fa>
                                                    </a>
                                                </div>
                                                <div class="t_cell">
                                                    <a
                                                        href="{{ config.plataforma.info.social.youtube }}"
                                                        target="_blank"
                                                        title="Youtube"
                                                    >
                                                        <fa name="youtube"></fa>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="speech-pos-2">
                                    <div class="speech-bubble-2">
                                        <p>Para conocer productos y tarifas:</p>
                                        <p class="email"><em>ventas@ogr.cl</em></p>
                                        <fa name="envelope-o" class="icono"></fa>
                                    </div>
                                </div>
                                <div class="speech-pos-2">
                                    <div class="speech-bubble-2 docente">
                                        <p>¿Dudas? Escríbenos:</p>
                                        <p class="email">
                                            <em>contacto@ogr.cl</em>
                                        </p>
                                        <fa name="envelope-o" class="icono"></fa>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="footer-wrap">
                <div class="footer-row">
                    <div class="footer-col">
                        <h3>Estudia con {{ config.plataforma.info.companyName }}</h3>
                        <ul>
                            <li>
                                <a
                                    href="#section1"
                                    easyScrollTo
                                    [easyScrollToPadding]="0"
                                    [easyScrollToDuration]="800"
                                    [easyScrollToEasing]="'easeInOutExpo'"
                                >
                                    Iniciar sesión | Registrarse
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#section2"
                                    easyScrollTo
                                    [easyScrollToPadding]="0"
                                    [easyScrollToDuration]="800"
                                    [easyScrollToEasing]="'easeInOutExpo'"
                                >
                                    Cómo estudiar
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#section3"
                                    easyScrollTo
                                    [easyScrollToPadding]="0"
                                    [easyScrollToDuration]="800"
                                    [easyScrollToEasing]="'easeInOutExpo'"
                                >
                                    Quiénes somos
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#section4"
                                    easyScrollTo
                                    [easyScrollToPadding]="0"
                                    [easyScrollToDuration]="800"
                                    [easyScrollToEasing]="'easeInOutExpo'"
                                >
                                    Blog
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#section5"
                                    easyScrollTo
                                    [easyScrollToPadding]="0"
                                    [easyScrollToDuration]="800"
                                    [easyScrollToEasing]="'easeInOutExpo'"
                                >
                                    Docentes
                                </a>
                            </li>
                            <!--<li>
								<a href="#section4"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">Testimonios</a>
							</li>-->
                            <li>
                                <a
                                    href="#section6"
                                    easyScrollTo
                                    [easyScrollToPadding]="0"
                                    [easyScrollToDuration]="800"
                                    [easyScrollToEasing]="'easeInOutExpo'"
                                >
                                    ¡Contáctanos!
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h3>Docentes</h3>
                        <ul>
                            <li>
                                <a href="https://docentes.puntajenacional.cl/">Portal de docentes</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h3>Más información</h3>
                        <ul>
                            <li>
                                <a routerLink="/condicionesUso">Términos y condiciones de uso</a>
                            </li>
                            <!--<li>
								<a href="#section2">Políticas de privacidad</a>
							</li>-->
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            <div class="footer-wrap">
                <div class="footer-row">
                    <div class="footer-col">
                        <div class="company-logo clearfix">
                            <a href="http://ogr.cl/">
                                <img
                                    class="footer-logo"
                                    src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/webp/logo_ogr_light.webp"
                                    alt="{{ config.plataforma.info.companyName }}"
                                />
                            </a>
                            <p>Todos los Derechos Reservados a "Open Green Road S.A." ®</p>
                        </div>
                    </div>
                    <div class="footer-col">
                        <p class="follow">¡Síguenos!</p>
                        <div class="socialMedia">
                            <a href="{{ config.plataforma.info.social.instagram }}" target="_blank" title="Instagram">
                                <fa name="instagram"></fa>
                            </a>
                            <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                                <fa name="youtube"></fa>
                            </a>
                            <a href="{{ config.plataforma.info.social.twitch }}" target="_blank" title="Twitch">
                                <fa name="twitch"></fa>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
<simple-modal></simple-modal>
