import { Component, OnInit, ViewChild } from "@angular/core"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Usuario } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "desafiosaber11.component.html",
    styleUrls: ["desafiosaber11.component.scss"]
})
export class Desafiosaber11Component implements OnInit {
    url: SafeResourceUrl
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected titleService: TitleService,
        protected usuariosService: Usuarios,
        protected authService: AuthService,
        protected sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.desafios.desafio_saber11")
        this.loadingLayout.standby()
        this.usuariosService
            .find(this.authService.getUserData().id, { raw: 1, methods: "[encrypted_password]" })
            .then((usuario: Usuario) => {
                let userObject = {
                    email: usuario.email,
                    password: (usuario as any).encrypted_password
                }
                let base64UserObject = btoa(JSON.stringify(userObject))
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
                    "http://desafiosaber11.herokuapp.com?token=" + base64UserObject
                )

                this.loadingLayout.ready()
            })
    }
}
