import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Horario, Horarios } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "horario",
    templateUrl: "./horario.component.html",
    styleUrls: ["./horario.component.scss"]
})
export class HorarioComponent implements OnInit {
    @Input() horarioId: number | null = null
    horario: Horario
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(protected horariosServices: Horarios) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.horariosServices.find(this.horarioId).then(horario => {
            this.horario = horario
            this.loadingLayout.ready()
        })
    }
}
