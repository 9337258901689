import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { ApiModule as PuntajeApiModule } from "@puntaje/puntaje/api-services"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { Desafiosaber11Component } from "./desafiosaber11.component"
import { DesafioComponent } from "./desafio.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { UtilModule } from "@puntaje/shared/core"
import { RouterModule } from "@angular/router"

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        PuntajeApiModule,
        DudasModule,
        Angular2FontawesomeModule,
        CUIInputsModule,
        CUILayoutsModule,
        CommonsLayoutsModule,
        BannersModule,
        UtilModule
    ],
    declarations: [Desafiosaber11Component, DesafioComponent],
    exports: [Desafiosaber11Component, DesafioComponent]
})
export class PuntajeDesafiosModule {}
