<el-titulo>{{ nombreEvaluacion }}</el-titulo>

<el-topside>
    <div class="fix-position">
        <widget-evaluacion-tiempo
            (onTimeUp)="onTimeup()"
            (onReady)="onTiempoReady($event)"
            [enableToggle]="true"
            [enableFixed]="false"
            [animateOnStart]="true"
            [collapseOnMobile]="true"
        ></widget-evaluacion-tiempo>
    </div>
</el-topside>

<progreso-preguntas-evaluacion-secuencial [class.hidden]="hideProgreso"></progreso-preguntas-evaluacion-secuencial>

<ng-container *ngIf="showTimeup">Se terminó el tiempo. En unos segundos la evaluación terminará.</ng-container>

<evaluacion-secuencial
    [class.hidden]="showTimeup"
    [evaluacionId]="evaluacionId"
    [evaluacionTiempo]="evaluacionTiempo"
    (onReadyEvaluacion)="onReadyEvaluacion($event)"
    (onLoadClockChange)="onLoadClockChange($event)"
    (evaluacionEntregada)="onEvaluacionEntregada($event)"
    [enableBtnMarkAsUncertain]="true"
    [enablePreguntasId]="false"
    class="with-instructions"
></evaluacion-secuencial>
