import { PlanClaseAlumnoComponent, ClasePlanClaseComponent, PlanClasesDeAlumnosComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

const routes: Routes = [
    {
        path: "plan_estudios",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [AuthGuard],
                data: { evaluacionTipos: ["saber11"] }
            }
        ]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard],
                data: { baseRoute: "/plan_estudios" }
            }
        ]
    },
    {
        path: "plan_estudios/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard],
                data: { planUrl: "/plan_estudios" }
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlumnosPlanClasesRouting {}
