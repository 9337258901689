<footer>
    <div class="footer-wrap">
        <div class="footer-row">
            <div class="footer-col">
                <h3>Estudia con {{ config.plataforma.info.companyName }}</h3>
                <ul>
                    <li>
                        <a href="#section1" easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800"
                            [easyScrollToEasing]="'easeInOutExpo'">
                            Iniciar sesión | Registrarse
                        </a>
                    </li>
                    <li>
                        <a href="/preguntas_frecuentes">Preguntas frecuentes</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h3>Docentes</h3>
                <ul>
                    <li>
                        <a href="https://www.puntajenacional.co/">Portal de docentes</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h3>Más información</h3>
                <ul>
                    <li>
                        <a routerLink="/condicionesUso">Términos y condiciones de uso</a>
                    </li>
                    <!--<li>
                        <a href="#section2">Políticas de privacidad</a>
                    </li>-->
                </ul>
            </div>
        </div>
    </div>
    <hr />
    <div class="footer-wrap">
        <div class="footer-row">
            <div class="footer-col">
                <div class="company-logo clearfix">
                    <a href="http://ogr.cl/">
                        <img class="footer-logo"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                            alt="{{ config.plataforma.info.companyName }}" />
                    </a>
                    <p>Todos los Derechos Reservados a "Open Green Road S.A." ®</p>
                </div>
            </div>
            <div class="footer-col">
                <p class="follow">¡Síguenos!</p>
                <div class="socialMedia">
                    <a href="{{ config.plataforma.info.social.instagram }}" target="_blank" title="Instagram">
                        <fa name="instagram"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                        <fa name="youtube"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.twitch }}" target="_blank" title="Twitch">
                        <fa name="twitch"></fa>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
