<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina" *ngIf="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>{{ nombreDesafio }}</ll-titulo>

<div *ngIf="desafioData">
    <div class="btn-container">
        <button class="btn btn-default btn-video btn-block btn_style" (click)="toggleVideo()">
            <ogr-icon name="video" class="icono"></ogr-icon>
            Ver video
        </button>
        <a
            class="btn btn-default btn-comenzar btn-block btn_style"
            [routerLink]="['/desafios', desafio, evaluacionId, 'realizar']"
        >
            <ogr-icon name="prueba" class="icono"></ogr-icon>
            Comenzar desafío
        </a>
    </div>
    <embedded-youtube-video [url]="desafioData.url_video" *ngIf="showVideo" class="video"></embedded-youtube-video>
</div>
