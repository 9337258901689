import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_pruebas.component.html"
})
export class HomePruebasComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        this.menuItems = [
            {
                route: "/pruebas/resultados/",
                params: { tipo_instrumento: "curricular", asignatura_id: 1 },
                label: "Historial curricular",
                text: "Encuentra aquí la lista de todas las pruebas curriculares que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/pruebas/compartidas/",
                params: { tipo_instrumento: "ensayo", asignatura_id: 1 },
                label: "Historial de pruebas compartidas",
                text: "Encuentra aquí la lista de todas las pruebas curriculares que te han compartido.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/pruebas/mi_progreso/",
                params: { tipo_instrumento: "curricular" },
                label: "Mi Progreso curricular",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "guia"
            },
            {
                route: "/pruebas/estadisticas/",
                params: { tipo_instrumento: "curricular" },
                label: "Estadísticas",
                text: "Encuentra aquí estadísticas generadas a partir de tus resultados.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }
}
