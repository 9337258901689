<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container">
                    <ogr-icon name="establecimiento-o" class="icon"></ogr-icon>
                </div>
            </div>
            <!-- <div class=" showcasebox_title_text">Mis carreras de interés</div> -->
            <div class="showcasebox_title_text">{{ "home.showcasebox.carreras_interes.titulo" | t }}</div>
        </div>
    </div>
    <div class="showcasebox_content">
        <loading-layout>
            <ng-container *ngIf="carreraSedes">
                <carreras-list-item
                    class="carrera"
                    *ngFor="let carrera of carreraSedes"
                    [carreraSede]="carrera"
                ></carreras-list-item>
            </ng-container>
        </loading-layout>

        <ng-container *ngIf="carreraSedes && carreraSedes.length == 0">
            <!-- <p class="not-found">Aún no has seleccionado tus carreras de interés.</p> -->
            <p class="not-found">{{ "home.showcasebox.carreras_interes.sin_carreras" | t }}</p>
        </ng-container>
        <div class="link-container">
            <!-- <a [routerLink]="['/mis-carreras']" class="bottom-link">Ver todas</a> -->
            <a [routerLink]="['/mis-carreras']" class="bottom-link">
                {{ "home.showcasebox.carreras_interes.ver_todas" | t }}
            </a>
        </div>
    </div>
</div>
