import { BaseForm, Validations, DateHelper, AppConfig } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"

declare const config: AppConfig

export class UsuarioEditColombiaForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "id", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true
            },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "date",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today()
            },
            email: {
                label: "Correo electrónico",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion: {
                            clasificacion: [
                                "Primer",
                                "Primero",
                                "Segundo",
                                "Tercero",
                                "Cuarto",
                                "Quinto",
                                "Sexto",
                                "Septimo",
                                "Octavo",
                                "Noveno",
                                "Decimo",
                                "Egresado",
                                "Once"
                            ]
                        },
                        clasificacion_tipo: { clasificacion_tipo: "nivel colombia" }
                    }
                }
            },
            lugar: {
                label: "Municipio",
                type: "autocomplete",
                visible: true,
                options: { class: Lugares, params: { lugar_tipo: config.plataforma.lugarTipo } }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            asignatura_id: { label: "Asignatura", type: "hidden", visible: false }
        }
    }
}
