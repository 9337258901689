import { Component, OnInit } from "@angular/core"

@Component({
    selector: "confirm-email",
    templateUrl: "./confirm-email.component.html",
    styleUrls: ["./confirm-email.component.scss"]
})
export class ConfirmEmailComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
