import { Component, Input, OnInit } from "@angular/core"

@Component({
    selector: "privacidad-proteccion-datos-colombia",
    templateUrl: "./privacidad-proteccion-datos-colombia.component.html",
    styleUrls: ["./privacidad-proteccion-datos-colombia.component.scss"]
})
export class PrivacidadProteccionDatosColombiaComponent implements OnInit {
    @Input() emailContacto: string = ""

    constructor() {}

    ngOnInit() {}
}
