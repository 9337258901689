<ll-over-title>
    <noticias-aviso-home [perfiles]="'todos,alumnos,alumnos libres,alumnos nomina'"></noticias-aviso-home>
    <tiempo-pro></tiempo-pro>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>Bienvenid@</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <sub-menu
            *ngIf="menuReady"
            [itemsMenu]="itemsMenu"
            [joyrideStep]="tour.actividades.step"
            [text]="tour.actividades.text"
            [prevTemplate]="prevBtn"
            [nextTemplate]="nextBtn"
            [doneTemplate]="doneBtn"
            stepPosition="right"
        ></sub-menu>
        <ng-container>
            <ng-template #nextBtn>
                <button type="button" class="btn btn-primary btn_style">
                    <fa name="chevron-right"></fa>
                    {{ "tour.btn.next" | t }}
                </button>
            </ng-template>
            <ng-template #prevBtn>
                <button type="button" class="btn btn-primary btn_style">
                    <fa name="chevron-left"></fa>
                    {{ "tour.btn.prev" | t }}
                </button>
            </ng-template>
            <ng-template #doneBtn>
                <button type="button" class="btn btn-default btn_style">{{ "tour.btn.ok" | t }}</button>
            </ng-template>
        </ng-container>
        <resumen-semana-showcasebox [linkEvaluacion]="linkEvaluacion"></resumen-semana-showcasebox>
        <br />
        <noticias-alumnos-showcasebox *ngIf="hasGrupoUsuarios"></noticias-alumnos-showcasebox>
        <dudas-showcase></dudas-showcase>
    </div>
    <div class="col-md-4 col-sm-6">
        <logros-ranking-showcasebox></logros-ranking-showcasebox>
        <ng-container *ngIf="hasGrupoUsuarios">
            <grupo-usuarios-notificaciones-showcasebox
                [maximoNotificaciones]="3"
            ></grupo-usuarios-notificaciones-showcasebox>
        </ng-container>
        <ng-container *ngIf="!hasGrupoUsuarios">
            <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
        </ng-container>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina" zona="showcasebox0"></arte-home-showcasebox>
        <arte-home-showcasebox [pagina]="pagina" zona="showcasebox1"></arte-home-showcasebox>
    </div>
</div>
<arte-zona-modal [pagina]="pagina"></arte-zona-modal>