import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "estudiar",
    templateUrl: "./estudiar.component.html"
})
export class EstudiarComponent implements OnInit {
    itemsMenu = [
        {
            name: "Planes de Estudio",
            route: "/planes_estudio"
        },
        {
            name: "Clases en vivo",
            params: { tipo: "saber11" },
            route: "/streaming/saber11"
        },
        {
            name: "Biblioteca de materiales",
            route: "/biblioteca",
            params: {}
        }
    ]
    horarioStreaming: string =
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje_colombia/home/horario_streaming.png"

    constructor(protected translatorService: I18nService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
