<ll-titulo>Estudiar</ll-titulo>

<div class="row align-item-start">
    <div class="col-md-4 col-sm-4 col-xs-12">
        <sub-menu [itemsMenu]="itemsMenu"></sub-menu>
    </div>
    <div class="col-md-8 col-sm-8 col-xs-12">
        <calendario-streaming-showcasebox [horarioStreaming]="horarioStreaming"></calendario-streaming-showcasebox>
        <br />
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <planes-estudios-showcasebox
                    [enablePlanesPersonales]="true"
                    [maxColumns]="1"
                ></planes-estudios-showcasebox>
            </div>
            <div class="col-md-6 col-sm-12">
                <material-favorito-showcasebox></material-favorito-showcasebox>
            </div>
        </div>
    </div>
</div>
