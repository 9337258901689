import { PostComponent, PostsComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { PublicLayoutComponent } from "../layouts/public-layout/public-layout.component"

export const bannersRoutes: Routes = [
    {
        path: "blog",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostsComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "posts/:slug",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "blog/:slug",
        component: PublicLayoutComponent,
        children: [
            {
                path: "",
                component: PostComponent,
                data: { external: true }
            }
        ]
    },
    {
        path: "public_blog",
        component: PublicLayoutComponent,
        children: [
            {
                path: "",
                component: PostsComponent,
                data: {
                    external: true
                }
            }
        ]
    }
]

export const alumnosBlogRouting = RouterModule.forChild(bannersRoutes)
