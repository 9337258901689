import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    RealizarEnsayoComponent,
    EvaluacionesMultiplesCompartidasComponent,
    GenerarEnsayoMultipleComponent,
    EvaluacionesCorregirComponent,
    EnsayoInstanciasComponent,
    EnsayosMensualesComponent,
    EvaluacionesCompartidasComponent,
    GenerarEnsayoComponent,
    ImprimirEnsayoComponent,
    ResultadosEnsayoComponent,
    EvaluacionesMultiplesRecomendadasComponent,
    RealizarEnsayoSecuencialComponent,
    EnsayosRecomendadosComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AppConfig, AuthGuard } from "@puntaje/shared/core"

import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"
import { HomePruebasComponent } from "./home_pruebas.component"
import { TokenGuard } from "@puntaje/puntaje/services"

import { ProgresoAlumnoComponent, EstadisticasAlumnoComponent } from "@puntaje/puntaje/core"

declare const config: AppConfig

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/subir",
        component: RestrictedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                data: { typePlatform: "alumnoTwo", perfil: "alumno", titulo: "Subir resultados de Evaluaciones" },
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    get evaluacionTipos() {
                        return (config.plataforma.evaluacionTipos = ["saber11"])
                    }
                }
            }
        ]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar_secuencial",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoSecuencialComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/imprimir",
        component: RestrictedLayoutComponent,
        children: [{ path: "", component: ImprimirEnsayoComponent, canActivate: [TokenGuard, AuthGuard, TokenGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar_secuencial",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoSecuencialComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/imprimir",
        component: RestrictedLayoutComponent,
        children: [{ path: "", component: ImprimirEnsayoComponent, canActivate: [TokenGuard, AuthGuard, TokenGuard] }]
    },
    {
        path: "evaluaciones/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "ensayos_breves",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayosMensualesComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    nombreEvaluacionTipoSingular: "simulacro",
                    nombreEvaluacionTipoPlural: "simulacros"
                }
            }
        ]
    },
    {
        path: "evaluaciones/:id/corregir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    //Calugas
    {
        path: "saber/generar_simulacro/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber/estadisticas",
        component: EstadisticasAlumnoComponent,
        canActivate: [TokenGuard, AuthGuard]
    },
    {
        path: "saber/mi_progreso",
        component: ProgresoAlumnoComponent,
        canActivate: [TokenGuard, AuthGuard]
    },
    {
        path: "saber/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeSaberComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },

    {
        path: "saber11",
        canActivate: [TokenGuard, AuthGuard],
        children: [
            {
                path: "",
                component: LoggedLayoutComponent,
                children: [{ path: "", component: HomeSaber11Component, canActivate: [TokenGuard, AuthGuard] }]
            },
            // simulacros multiples
            {
                path: "generar_simulacro",
                component: LoggedLayoutComponent,
                children: [
                    { path: "", component: GenerarEnsayoMultipleComponent, canActivate: [TokenGuard, AuthGuard] }
                ]
            },
            {
                path: "compartidos",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: EvaluacionesMultiplesCompartidasComponent,
                        canActivate: [TokenGuard, AuthGuard]
                    }
                ]
            },
            {
                path: "recomendados",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: EvaluacionesMultiplesRecomendadasComponent,
                        canActivate: [TokenGuard, AuthGuard]
                    }
                ]
            },

            // simulacros por asignatura
            {
                path: "generar_simulacro/:asignatura_id",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: GenerarEnsayoComponent,
                        canActivate: [TokenGuard, AuthGuard]
                    }
                ]
            },
            {
                path: "estadisticas",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: EstadisticasAlumnoComponent,
                        canActivate: [TokenGuard, AuthGuard],
                        data: {
                            get evaluacionTipos() {
                                return (config.plataforma.evaluacionTipos = ["saber11"])
                            }
                        }
                    }
                ]
            },
            {
                path: "mi_progreso",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: ProgresoAlumnoComponent,
                        canActivate: [TokenGuard, AuthGuard],
                        data: {
                            get evaluacionTipos() {
                                return (config.plataforma.evaluacionTipos = ["saber11"])
                            }
                        }
                    }
                ]
            },
            {
                path: "resultados",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: EnsayoInstanciasComponent,
                        canActivate: [TokenGuard, AuthGuard],
                        data: {
                            get evaluacionTipos() {
                                return (config.plataforma.evaluacionTipos = ["saber11"])
                            }
                        }
                    }
                ]
            },
            {
                path: "compartidos/asignatura",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: EvaluacionesCompartidasComponent,
                        canActivate: [TokenGuard, AuthGuard]
                    }
                ]
            },
            {
                path: "recomendados_por_asignatura",
                component: LoggedLayoutComponent,
                children: [
                    {
                        path: "",
                        component: EnsayosRecomendadosComponent,
                        canActivate: [TokenGuard, AuthGuard],
                        data: {
                            titulo: "Recomendados por componentes",
                            get evaluacionTipos() {
                                return (config.plataforma.evaluacionTipos = ["saber11"])
                            }
                        }
                    }
                ]
            }
        ]
    },

    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/estadisticas",
        component: EstadisticasAlumnoComponent,
        canActivate: [TokenGuard, AuthGuard]
    },
    {
        path: "pruebas/mi_progreso",
        component: ProgresoAlumnoComponent,
        canActivate: [TokenGuard, AuthGuard]
    },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
