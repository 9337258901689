import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        carreras: urls.carrerasUrl,
        achievements: urls.achievementsUrl
    },
    googleAnalyticsPropertyId: {
        alumnos: "UA-51752657-1",
        profesores: "UA-9451075-5"
    },
    googleTagManagerPropertyId: {
        profesores: "GTM-NJRV2W3",
        alumnos: "GTM-K86RLN2"
    },
    globalSiteTag: {
        alumnos: {
            propertyId: "G-6R382NYDGJ"
        },
        profesores: {
            propertyId: "G-QEQG88HNSV"
        }
    },
    facebookAnalyticsPropertyId: {},
    domain: {
        profesores: "docentes.puntajenacional.co",
        alumnos: "www.puntajenacional.co",
        admin: "admin.puntajenacional.co"
    },
    facebook: {
        appId: "121919589924555",
        apiVersion: "v10.0"
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: null,
    facebookApiVersion: null,
    domain: {
        profesores: "www.puntajenacional.co",
        alumnos: "alumnos.puntajenacional.co",
        admin: "admin.puntajenacional.co"
    }
}
