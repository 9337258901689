import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { selectEvaluacionTipoAliases, State } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./evaluaciones_multiples_recomendadas.component.html"
})
export class EvaluacionesMultiplesRecomendadasComponent implements OnInit {
    asignaturaId: number
    tipoInstrumento: string

    sub: Subscription
    subAlias: Subscription
    evaluacionTipoAlias: string

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones_multiples.recomendadas")
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"]
        })

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoInstrumento]
        })
    }
}
