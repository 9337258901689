<ll-titulo>{{ "practicar.titulo" | t }}</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <sub-menu *ngIf="menuReady" [itemsMenu]="itemsMenu"></sub-menu>
    </div>
    <div class="col-md-4 col-sm-6">
        <resumen-semanal-practicar-showcasebox
            [linkEvaluacion]="linkEvaluacion"
        ></resumen-semanal-practicar-showcasebox>
        <instrumento-mensual-showcasebox
            [tipoInstrumento]="config.plataforma.instrumentoMensualTipo"
        ></instrumento-mensual-showcasebox>
        <instrumentos-mensuales-ranking
            [tipoInstrumento]="config.plataforma.instrumentoMensualTipo"
        ></instrumentos-mensuales-ranking>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="'Practicar'" [zona]="'showcasebox1'"></arte-home-showcasebox>
        <br />
        <logros-ranking-showcasebox></logros-ranking-showcasebox>
        <entrenador-showcasebox></entrenador-showcasebox>
    </div>
</div>
