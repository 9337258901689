import { HttpClient } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { Validations } from "@puntaje/shared/core"
import { BaseContactFormComponent } from "./base-contact-form/base-contact-form.component"
import { ContactForm } from "./contact_form.interface"

@Component({
    selector: "puntajeco-contact-form",
    templateUrl: "puntajeco-contact-form.component.html",
    styleUrls: ["puntajeco-contact-form.component.scss"]
})
export class PuntajeCoContactFormComponent extends BaseContactFormComponent implements OnInit {
    form: UntypedFormGroup
    contactForm: ContactForm
    tipos_contacto: string[] = []
    radio_motivo: number = 0
    isValid: boolean
    constructor(protected formBuilder: UntypedFormBuilder, protected http: HttpClient) {
        super(formBuilder, http)
    }

    ngOnInit() {
        this.buildForm()
    }

    beforeBuildForm() {
        this.form = this.formBuilder.group({
            nombre: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(200)]],
            apellido_paterno: [
                "",
                [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(200)]
            ],
            apellido_materno: [
                "",
                [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(200)]
            ],
            email: ["", [<any>Validators.required, <any>Validators.email]],
            telefono: ["", [<any>Validators.required, <any>Validators.minLength(5), <any>Validators.maxLength(15)]],
            establecimiento: ["", [<any>Validators.minLength(2), <any>Validators.maxLength(250)]],
            lugar: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(300)]],
            rut: ["", [<any>Validators.required, Validations.validateNUIP]],
            motivo: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(2000)]],
            motivo_general: ["", [<any>Validators.required]]
        })
    }

    buildForm() {
        super.buildForm()
    }
}
