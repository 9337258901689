<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="calendario"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "estudiar.streaming.horario" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <div class="calendario">
            <horario></horario>
        </div>
    </cui-showcasebox-content>
</cui-showcasebox>
