import { SharedLayoutComponent } from "@puntaje/platforms/pnco"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/pnco"
import {
    UsuariosEditComponent,
    UsuarioPerfilComponent,
    TutorConfirmarComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    RegisterConfirmationComponent,
    IncompleteRegisterComponent,
    RegisterComponent,
    CompletarRegistroSocialComponent,
    UsuarioPerfilAlumnoComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { TokenGuard } from "@puntaje/puntaje/services"
import { ConfirmEmailComponent } from "./register/confirm-email/confirm-email.component"

import {
    UsuariosEditColombiaComponent,
    RegisterCompletarPuntajeColombiaComponent,
    RegisterCompletarSocialPuntajeColombiaComponent
} from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [TokenGuard, AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/register",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent }]
    },
    {
        path: "usuarios/registro",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent }]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: CompletarRegistroComponent,
                data: { component: RegisterCompletarPuntajeColombiaComponent }
            }
        ]
    },
    {
        path: "usuarios/:id/completar_registro_social",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: CompletarRegistroSocialComponent,
                data: { component: RegisterCompletarSocialPuntajeColombiaComponent }
            }
        ]
    },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    {
        path: "usuarios/confirmar_email",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ConfirmEmailComponent }]
    },

    //{ path: 'usuarios/new', component: UsuariosNewComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    component: UsuariosEditColombiaComponent,
                    enableAsignaturaDefinida: false
                }
            }
        ]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    component: UsuariosEditColombiaComponent,
                    enableAsignaturaDefinida: false
                }
            }
        ]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuarioPerfilAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { tab: "" }
            }
        ]
    },
    {
        path: "usuarios/:id/logros",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuarioPerfilAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { tab: "" }
            }
        ]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent }]
    },
    {
        path: "usuarios/:id/logros",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuarioPerfilComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { tab: "logros" }
            }
        ]
    },
    //{ path: 'usuarios/:id/edit', component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/edicion', component: EdicionComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/delete', component: UsuariosDeleteComponent, canActivate: [TokenGuard, AuthGuard] }
    {
        path: "tutor/confirmar/:token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: TutorConfirmarComponent }]
    },
    {
        path: "pending_info",
        component: SharedLayoutComponent,
        children: [{ path: "", component: IncompleteRegisterComponent }]
    }
]

export const routing = RouterModule.forChild(routes)
