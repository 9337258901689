import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "participa",
    templateUrl: "./participa.component.html"
})
export class ParticipaComponent implements OnInit {
    itemsMenu = [
        {
            route: "/dudas/new",
            name: this.translatorService.translate("participa.itemsmenu.nueva_duda", {})
        },
        {
            name: this.translatorService.translate("participa.itemsmenu.responder_duda", {}),
            route: "/dudas"
        }
    ]

    redesSociales = [
        {
            class: "instagram",
            title: "Instagram"
        },
        {
            class: "youtube",
            title: "Youtube"
        },
        {
            class: "twitch",
            title: "Twitch"
        },
        {
            class: "tiktok",
            title: "TikTok"
        }
    ]
    constructor(protected translatorService: I18nService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
