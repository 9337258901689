import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "home_planes_estudios.component.html"
})
export class HomePlanesEstudiosComponent {
    config: typeof config = config
    menuItems: MenuItemBoxData[] = []

    constructor(protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        this.menuItems = [
            {
                route: "/planes_estudio",
                params: {},
                label: "Planes de Estudio",
                text: "Estudia con los Planes de Estudio que te proponemos.",
                linkText: "Ir a Planes de Estudio",
                icon: "plan-estudio"
            } /*,
		{
			route: '/planes_personales', 
			params: {},
			label: "Planes Personales",
			text: "Estudia con los Planes de Estudio que te proponemos.",
			linkText: "Ir a Planes personales",
			icon: "plan-estudio"
		}*/
        ]
    }
}
