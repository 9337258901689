<footer class="print-hide">
    <div class="footer-wrap">
        <div class="footer-row">
            <div class="footer-col">
                <h3>{{ "footer.aprende.intro" | t }} {{ config.plataforma.info.companyName }}</h3>
                <ul>
                    <li>
                        <a routerLink="/">{{ "footer.aprende.inicio" | t }}</a>
                    </li>
                    <li>
                        <a routerLink="/estudiar">{{ "footer.aprende.estudiar" | t }}</a>
                    </li>
                    <li>
                        <a routerLink="/practicar">{{ "footer.aprende.practicar" | t }}</a>
                    </li>
                    <li>
                        <a routerLink="/tu_progreso">{{ "footer.aprende.progreso" | t }}</a>
                    </li>
                    <li>
                        <a routerLink="/orientacion">{{ "footer.aprende.orientacion" | t }}</a>
                    </li>
                    <li>
                        <a routerLink="/participa">{{ "footer.aprende.participa" | t }}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col central">
                <h3>{{ "footer.contacto.titulo" | t }}</h3>
                <div class="contact-wrap">
                    <div class="contact-icon">
                        <fa name="envelope" class="icono"></fa>
                    </div>
                    <p class="contact-content">
                        <span>{{ "footer.contacto.escribe" | t }}</span>
                        <span>
                            <b>{{ "footer.contacto.correo" | t }}</b>
                        </span>
                    </p>
                </div>
            </div>
            <div class="footer-col">
                <h3>{{ "footer.info.titulo" | t }}</h3>
                <ul>
                    <li>
                        <a routerLink="/condicionesUso">{{ "footer.info.terms" | t }}</a>
                    </li>
                    <!--<li>
                        <a>Políticas de privacidad</a>
                    </li>
                    <li>
                        <a>Mapa del sitio</a>
                    </li>-->
                    <li>
                        <a routerLink="/ayudas">{{ "footer.info.ayuda" | t }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <hr />
    <div class="footer-wrap">
        <div class="footer-row">
            <div class="footer-col">
                <div class="company-logo clearfix">
                    <a href="http://ogr.cl/">
                        <img
                            class="footer-logo"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                    <p>
                        Todos los Derechos Reservados a "Open Green Road Colombia S.A.S."
                        <br />
                        © 2018 Copyright
                    </p>
                </div>
            </div>
            <div class="footer-col">
                <p class="follow">{{ "footer.siguenos" | t }}</p>
                <div class="socialMedia">
                    <a href="{{ config.plataforma.info.social.instagram }}" target="_blank" title="Instagram">
                        <fa name="instagram"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                        <fa name="youtube"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.twitch }}" target="_blank" title="Twitch">
                        <fa name="twitch"></fa>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
