import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { last, first } from "rxjs/operators"

@Component({
    templateUrl: "home_biblioteca.component.html"
})
export class HomeBibliotecaComponent extends MenuComponent {
    config: typeof config = config
    cursos: Clasificacion[]
    menuItems: MenuItemBoxData[] = []
    enableMenu: boolean = false

    constructor(
        protected csService: Clasificaciones,
        protected store: Store<State>,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        this.setCursos()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setCursos() {
        let configCursos = config.evaluaciones["saber"].cursosMaterial
        let params = {
            clasificacion: {
                clasificacion: configCursos.map((c: any) => c.clasificacion)
            },
            clasificacion_tipo: { clasificacion_tipo: "nivel colombia" }
        }
        this.csService.where(params).then((clasificaciones: Clasificacion[]) => {
            this.cursos = configCursos.map((c: any) => {
                return clasificaciones.find((cs: any) => cs.clasificacion == c.clasificacion)
            })
            this.setMenuItems()
        })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        let curso = 1
        if (this.cursos && this.cursos.length > 0) curso = this.cursos[0].id
        this.menuItems = [
            // {
            //     route: "biblioteca/",
            //     params: { tipo_instrumento: "saber", curso_id: curso },
            //     label: "Material Saber 3",
            //     text: "Encuentra guías y videos con material para la prueba Saber 3º.",
            //     linkText: "Ver material",
            //     icon: "saber-3-o"
            // },
            // {
            //     route: "biblioteca/",
            //     params: { tipo_instrumento: "saber", curso_id: this.cursos[1].id },
            //     label: "Material Saber 5",
            //     text: "Encuentra guías y videos con material para la prueba Saber 5º.",
            //     linkText: "Ver material",
            //     icon: "saber-5-o"
            // },
            // {
            //     route: "biblioteca/",
            //     params: { tipo_instrumento: "saber", curso_id: this.cursos[2].id },
            //     label: "Material Saber 9",
            //     text: "Encuentra guías y videos con material para la prueba Saber 9º.",
            //     linkText: "Ver material",
            //     icon: "saber-9-o"
            // },
            {
                route: "biblioteca/",
                params: {
                    tipo_instrumento: "saber11",
                    curso_id: this.cursos[3].id,
                    asignatura_id: +asignaturasByEvaluacionTipo["saber11"][0].id
                },
                label: "Material Saber 11",
                text: "Encuentra guías y videos con material para la prueba Saber 11º.",
                linkText: "Ver material",
                icon: "saber-11-o"
            }
        ]
        this.enableMenu = true
    }
}
