<div class="content">
    <p>
        El presente documento establece los Términos de uso y Condiciones generales aplicables al uso de la Plataforma
        Web:
        <span>{{ " " }}</span>
        <span class="entity-name">{{ "www." + companyName }}</span>
        (en adelante
        <span class="entity-name">"{{ companyName }}"</span>
        ), los cuales se hacen aplicables a todos los Usuarios que participen del sitio web, en cualquier parte del
        mundo en el cual se tenga acceso a la Plataforma, a menos que se indique lo contrario.
    </p>
    <p>
        Al tenor del presente documento, se entiende que es Usuario toda persona que ingrese, utilice y/o participe de
        uno o más servicios que se ofrecen en la Plataforma.
    </p>
    <p>
        El Usuario y/o su representante legal, según corresponda, declara haber leído y acepta expresamente todos los
        Términos y las Condiciones de uso del Sitio Web, incluyendo las políticas de privacidad, de almacenamiento y
        tratamiento de datos personales establecidas por
        <span class="entity-name">{{ companyName }}</span>
        para acceder y hacer uso de los servicios.
    </p>
    <p>
        De conformidad a la Ley 1581 del año 2012 y el Decreto Reglamentario 1377 de 2013, de la legislación colombiana,
        en el evento de que el Usuario sea menor de edad, se solicita que lea, entienda y acepte los siguientes
        <b>Términos y Condiciones de uso</b>
        , actuando representado por su representante o tutor legal, en el acto de aceptación de éstos.
    </p>
    <p>
        En caso de que cualquier Usuario no esté de acuerdo con los Términos y Condiciones de uso del Sitio Web,
        incluyendo las políticas de privacidad y de almacenamiento y tratamiento de datos personales de
        <span class="entity-name">{{ companyName }}</span>
        , así como de sus posteriores modificaciones y/o actualizaciones, se ruega no hacer uso de ella.
    </p>
    <p>
        Los Términos y Condiciones podrán ser modificados, total o parcialmente, y de manera unilateral por
        <span class="entity-name">{{ companyName }}</span>
        y dichos cambios serán plenamente vigentes a partir del momento mismo en que sean publicados en el sitio web de
        <span class="entity-name">{{ companyName }}</span>
        . Será responsabilidad del Usuario estar informado de las modificaciones efectuadas por
        <span class="entity-name">{{ companyName }}</span>
        , aunque no medie notificación alguna. De todas maneras, aunque no signifique asunción de responsabilidad por
        parte de
        <span class="entity-name">{{ companyName }}</span>
        , ésta se ocupará de notificar a los Usuarios de las modificaciones, oportunamente
    </p>
    <p>
        Respecto a cualquier modificación a las
        <b>Políticas de privacidad</b>
        donde se incluye el tratamiento de datos personales,
        <span class="entity-name">{{ companyName }}</span>
        se ocupará de notificar a los Usuarios de las modificaciones para su aceptación, de forma previa a su entrada en
        vigencia.
    </p>
    <p>
        Encontrándose vigentes los nuevos Términos y Condiciones, incluyendo las Políticas de privacidad, si un Usuario
        usa de los servicios, se entenderá que aceptaron previamente las actualizaciones en la medida en que la ley lo
        permita.
    </p>
    <p>
        Cualquier violación por parte de los Usuarios, a los Términos y Condiciones que en este documento se indican, le
        otorga a
        <span class="entity-name">{{ companyName }}</span>
        el derecho de suspender la cuenta del Usuario desde la cual se haya efectuado la acción u omisión indebida.
    </p>

    <h2>1.- Servicios que ofrece PuntajeNacional.co</h2>
    <h3>1.1.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        es una Plataforma web que se ofrece de forma libre y gratuita a los Usuarios, en la cual de forma periódica y
        sistemática se publican contenidos e información útil para el estudio y preparación académica, mediante la
        generación periódica de pruebas de práctica, informes estadísticos, material educativo y otros contenidos
        relacionados con la preparación.
    </p>

    <h3>1.2.</h3>
    <p>
        La información contenida en
        <span class="entity-name">{{ companyName }}</span>
        se entiende complementaria a los materiales y a los estudios que el Usuario pueda recibir por otros medios. Para
        <span class="entity-name">{{ companyName }}</span>
        no existe la obligación de satisfacer la totalidad de las necesidades formativas o educativas del Usuario.
    </p>

    <h3>1.3.</h3>
    <p>
        No obstante el carácter libre y gratuito de la Plataforma web, su utilización sólo se puede ejercer como Usuario
        debidamente registrado, de conformidad a las instrucciones que se indican en lo sucesivo.
    </p>

    <h2>2.- Formas de registro en la Plataforma web</h2>
    <h3>2.1.</h3>
    <p>
        <b>Contratación (gratuita) para uso individual</b>
        . Para ser Usuario de
        <span class="entity-name">{{ companyName }}</span>
        es obligatorio completar el formulario de registro (“
        <i>Registro de Usuarios</i>
        ”), con la información personal que se pide en él. El Usuario se obliga a verificar que la información personal
        aportada en el formulario de inscripción sea verdadera y asume, desde ya, el compromiso de actualizarla cada vez
        que sea necesario.
        <span class="entity-name">{{ companyName }}</span>
        no se responsabiliza de la veracidad de los datos otorgados por los Usuarios, siendo estos últimos, o sus
        representantes legales, según corresponda, quienes responderán íntegramente por el contenido de los mismos.
    </p>

    <h4>2.1.1.</h4>
    <p>
        Salvo que se indique lo contrario, las respuestas a las preguntas del “Registro de Usuarios” son voluntarias,
        sin que la falta de respuesta implique una merma en la calidad o cantidad de servicios posibles de usar, a menos
        que oportunamente se indique otra cosa.
    </p>

    <h4>2.1.2.</h4>
    <p>
        El Usuario será el único responsable por el uso y actividad efectuada desde su cuenta. Los datos de acceso son
        personales e intransferibles, siendo responsabilidad del Usuario resguardar su privacidad. El Usuario registrado
        se compromete a notificar a
        <span class="entity-name">{{ companyName }}</span>
        de cualquier uso no autorizado de su clave, apenas tenga noticias del evento.
    </p>

    <h3>2.2.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente
        aceptado.
    </p>

    <h3>2.3.</h3>
    <p>
        Las personas que no cuenten con la capacidad legal para contratar y registrarse en nuestra plataforma y hacer
        uso de los servicios, deberán ser asistidas y autorizadas por sus representantes legales. Toda persona que haga
        uso de la Plataforma y que acepte los términos y condiciones contenidos en este instrumento, se entiende que
        cuenta con la capacidad legal o representación necesaria para su actuación y aceptación de estos Términos y
        Condiciones.
    </p>

    <h2>3.- Envío de informaciones vía correo electrónico</h2>
    <h3>3.1.</h3>
    <p>
        El Usuario acepta y autoriza que
        <span class="entity-name">{{ companyName }}</span>
        pueda enviar información que considere relevante para los Usuarios, a la dirección de correo que éste indique en
        el Formulario de registro. La información podrá ser referida a
        <span class="entity-name">{{ companyName }}</span>
        , o bien, a información publicitaria de terceros que esté relacionada con las áreas de educación. No obstante,
        <span class="entity-name">{{ companyName }}</span>
        pondrá a disposición del Usuario mecanismos sencillos y explícitos para solicitar dejar de recibir correos. A
        este respecto, se hacen aplicables las cláusulas relativas al Uso de datos personales que están contenidas en el
        Punto Séptimo del presente documento.
    </p>

    <h2>4.- Derechos y obligaciones por la Información contenida en el Sitio web</h2>
    <h3>4.1.</h3>
    <p>
        <b>Propiedad Intelectual</b>
        . Todo el contenido, organización, gráficas, diseño, compilación y otros aspectos del Sitio web
        <span class="entity-name">{{ companyName }}</span>
        se encuentran protegidos por las respectivas leyes de Propiedad Industrial y derechos de autor, vigentes en la
        República de Colombia, así como por los Tratados internacionales que rigen sobre la materia, en relación a
        derechos de propiedad intelectual e industrial que tengan terceros reconocidos en el extranjero. Su copia,
        redistribución, publicación, total o parcial, están prohibidas por la ley.
        <span class="entity-name">{{ companyName }}</span>
        hace expresa reserva del ejercicio de todas las acciones que le asistan, sean civiles, administrativas y
        penales, destinadas al resguardo de sus legítimos derechos de propiedad industrial y derechos de autor.
    </p>

    <h4>4.1.1.</h4>
    <p>
        El Usuario declara y acepta conocer que toda la información y datos publicados en el presente sitio web son de
        propiedad de la sociedad Ingeniería de Sistemas Open Green Road S.A. (desde ahora, O.G.R. S.A.), de la República
        de Chile, quien posee para todos los efectos legales los derechos de propiedad intelectual, industrial, derechos
        de autor y derechos conexos sobre los mismos. El acceso a
        <span class="entity-name">{{ companyName }}</span>
        no otorga a los Usuarios derecho, ni titularidad alguna sobre la propiedad intelectual de los contenidos que
        alberga esta Plataforma.
    </p>

    <h4>4.1.2.</h4>
    <p>
        Se entenderá por “
        <i>Contenidos</i>
        ” todas las informaciones, mensajes, gráficos, dibujos, diseños, logotipos, isotipos, nombres, marcas, archivos
        de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material accesible a
        través del Sitio web de
        <span class="entity-name">{{ companyName }}</span>
        .
    </p>

    <h4>4.1.3.</h4>
    <p>
        El usuario se obliga a: usar los contenidos de forma diligente, correcta y lícita; y, a no emplear los
        contenidos y, en particular, la información de cualquier otra clase obtenida a través de la Plataforma, para
        emitir publicidad o para obtener beneficio económico bajo cualquier formato, en Colombia o en el extranjero.
    </p>

    <h2>5.- Licencia para el uso de la Plataforma web</h2>
    <h3>5.1.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        otorga al Usuario una licencia limitada, personal, no exclusiva, intransferible, no comercial y totalmente
        revocable para utilizar el Sitio Web
        <span class="entity-name">{{ "www." + companyName }}</span>
        en los dispositivos móviles o fijos en que se encuentre disponible la Plataforma, efectuar copias y/o descargas
        de documentos para uso personal, de conformidad a los términos contenidos en este documento.
        <span class="entity-name">{{ companyName }}</span>
        se reserva todos los derechos sobre el Sitio Web no expresamente concedidos aquí.
    </p>
    <h3>5.2.</h3>
    <p>
        El Usuario acepta la prestación del servicio de este sitio, su contenido y materiales, en las condiciones en que
        efectivamente se entregan y asume personalmente las consecuencias de su decisión de hacer uso del mismo.
    </p>
    <h3>5.3.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        no se hace responsable de ningún daño sufrido por el usuario que realiza una copia, descarga, transferencia,
        distribución o uso de cualquier contenido del Sitio Web protegido. No obstante, si bien
        <span class="entity-name">{{ companyName }}</span>
        ha tomado las medidas necesarias para revisar los programas y archivos, a efectos de considerarlos libres de
        virus u otros medios tecnológicos de tipo destructivo,
        <span class="entity-name">{{ companyName }}</span>
        no se responsabiliza por ningún daño que se pueda ocasionar derivado de archivos maliciosos.
    </p>
    <h3>5.4.</h3>
    <p>
        El Usuario es el único responsable del uso de los Contenidos y/o de los datos que consulta o transfiere en
        Internet a través de la Plataforma.
    </p>
    <h3>5.5.</h3>
    <p>
        El Usuario reconoce y acepta que el uso de Internet deriva de un servicio público de carácter internacional, sin
        que
        <span class="entity-name">{{ companyName }}</span>
        pueda ser responsable directa o indirectamente de las acciones administrativas, técnicas o regulatorias que
        terceros (privados o públicos) apliquen a sus redes para proteger la integridad del contenido, sistemas,
        usuarios, etc.
    </p>

    <h2>6.- Fallas en la Plataforma</h2>
    <h3>6.1.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        no se responsabiliza por cualquier tipo de daño, perjuicio o pérdida del Usuario causados por fallas en el
        sistema, en el servidor o en Internet.
    </p>

    <h2>7.- Política de Privacidad</h2>
    <h3>7.1.</h3>
    <p>
        Por medio del presente aviso,
        <span class="entity-name">{{ companyName }}</span>
        informa a los Usuarios del sitio web acerca de su política de protección de datos de carácter personal. Del
        mismo modo, el Usuario al aceptar los Términos y Condiciones de Uso, de manera informada, acepta y otorga su
        consentimiento y autorización expresa a la sociedad chilena, Ingeniería de Sistemas Open Green Road S.A., como
        encargado y responsable de tratamiento de datos personales, y a su filial, Open Green Road Colombia SAS, Número
        de Identificación Tributaria (NIT): 900751762-0, como encargado de tratamiento de datos personales, a efectuar
        tratamiento de sus datos personales de la forma que se indica en lo sucesivo.
    </p>

    <h3>7.2.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        declara que respeta y cumple con la obligación de proteger la privacidad de los datos personales de toda persona
        natural que visite su sitio web, en el almacenamiento y tratamiento de datos personales, sometiéndose al
        ordenamiento jurídico vigente en la República de Colombia, en particular, a lo indicado en el Artículo 15 de la
        Constitución Política de Colombia, la Ley 1581 del año 2012 y en el Decreto Reglamentario 1377 de 2013; así como
        también, para el tratamiento que efectúe O.G.R. S.A. en el territorio chileno, estará a lo indicado en la Ley N°
        19.628 sobre protección de la vida privada, en todo lo que no sea contrario a la legislación colombiana y a
        leyes de los países en que se encuentre disponible
        <span class="entity-name">{{ companyName }}</span>
        .
    </p>

    <h3>7.3.</h3>
    <p>
        Los datos personales consisten en toda aquella información de propiedad de personas naturales, identificadas o
        identificables (en adelante, los “
        <i>Datos Personales</i>
        ”) que sean otorgadas por las instituciones educativas suscriptoras de servicios y/u obtenidas directamente de
        los Usuarios.
    </p>

    <h3>7.4.</h3>
    <p>
        Respecto a los datos de menores de edad que O.G.R. S.A., y su filial colombiana, mantengan en el banco de datos,
        se tiene en especial consideración el respeto a los derechos de protección de datos personales de los niños,
        niñas y adolescentes, siendo para O.G.R. S.A. primordial perseguir con el tratamiento de datos el interés
        superior de los niños, niñas y adolescentes, asegurando sin excepción alguna el respeto de sus derechos. En
        especial, con el tratamiento de datos se pretende aportar al desarrollo educativo de los Usuarios, con el fin de
        propender a la disminución de la brecha educacional que existe en Latinoamérica.
    </p>
    <h3>7.5.</h3>
    <p>
        En todo caso, los datos serán objeto de un tratamiento automatizado e incorporados a ficheros de propiedad de
        <span class="entity-name">{{ companyName }}</span>
        , que no son accesibles al público; pudiendo incluir: nombre, fecha de nacimiento, teléfono, país y región de
        residencia, dirección de correo electrónico, o cualquier otra información que permita individualizar al Usuario
        y que, en ningún caso, serán datos sensibles. Así también,
        <span class="entity-name">{{ companyName }}</span>
        podrá recabar información respecto de la cantidad y frecuencia de los visitantes a la Plataforma y a sus
        distintas páginas contenidas en el sitio web, de conformidad con lo indicado en Punto Octavo sobre el Uso de
        Cookies.
        <br />
        En la eventualidad de que
        <span class="entity-name">{{ companyName }}</span>
        requiera obtener algún dato sensible por parte de los Usuarios, lo informará previamente y solicitará
        autorización previa expresa del Usuario para su tratamiento.
    </p>
    <h3>7.6.</h3>
    <p>
        Los datos personales de los Usuarios, indicados en el Punto anterior, serán tratados por
        <span class="entity-name">{{ companyName }}</span>
        , con los siguientes propósitos: primero, para la adecuada prestación de los servicios ofrecidos, intentando
        actuar personalizadamente a las necesidades e intereses del Usuario; segundo, para estudiar y poner en práctica
        mejoras a los servicios, haciéndolos más atractivos y útiles al Usuario; tercero, para fines informativos y
        publicitarios, tanto de
        <span class="entity-name">{{ companyName }}</span>
        como de terceros, por ofertas que puedan ser relevantes para los Usuarios; y, cuarto, para ser transferidos a
        instituciones universitarias, con el propósito de que los Usuarios sean contactados por éstas para entregarles
        información que pueda ser relevante para los Usuarios.
    </p>
    <h3>7.7.</h3>
    <p>
        El Usuario consiente y autoriza expresamente a
        <span class="entity-name">{{ companyName }}</span>
        para contratar servicios de tratamiento y/o análisis de datos a terceros proveedores, en Colombia o en Chile,
        caso en el cual los contratos con ellos tendrán cláusulas de privacidad similares a las contenidas en este
        documento. Del mismo modo, el Usuario consiente y autoriza expresamente a
        <span class="entity-name">{{ companyName }}</span>
        a usar el contenido de la información estadística y comunicarla a terceros, en Colombia o en Chile, siempre y
        cuando la información omita las señas que puedan identificar al Usuario que la proveyó. 1
    </p>
    <h3>7.8.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        podrá utilizar los Datos Personales para lograr una buena gestión y administración del Sitio Web; para poder
        prestar, ampliar y mejorar los servicios que se ofrecen a través de la Plataforma web; para adecuar dichos
        servicios a las preferencias y gustos de los Usuarios; y para actualizar la información sobre servicios,
        productos y contenidos ofrecidos por
        <span class="entity-name">{{ companyName }}</span>
        .
    </p>
    <h3>7.9.</h3>
    <p>
        El Usuario acepta y autoriza expresa e inequívocamente a Open Green Road Colombia SAS a transferir los datos de
        los Usuarios a la sociedad Ingeniería de Sistemas Open Green Road S.A., con domicilio en Chile, siendo necesario
        para la ejecución del contrato y prestación de servicios, toda vez que en la casa matriz chilena se encuentran
        disponibles las tecnologías necesarias para el correcto funcionamiento de la plataforma web.
    </p>
    <h3>7.10.</h3>
    <p>
        En todo caso, el Usuario podrá ejercer sus derechos de acceso, cancelación, revocación, rectificación y
        oposición, así como también, siempre tendrá el derecho de estar informado de las cesiones de datos que
        <span class="entity-name">{{ companyName }}</span>
        efectúe a terceros.
    </p>
    <h3>7.11.</h3>
    <p>
        El Usuario podrá revocar su autorización para la comunicación a terceros de sus Datos Personales cuando
        correspondan a aquellos datos que no se encontraban en fuentes accesibles al público. En aquellos casos en que
        el Usuario revoque su autorización para la comunicación de sus Datos Personales y esto sea imprescindible para
        el otorgamiento correcto de los servicios ofrecidos al Usuario, se entiende que, en el acto de comunicar su
        negativa a la comunicación de Datos Personales, el Usuario ha cancelado su registro en el sitio web, haciéndose
        responsable el Usuario de los efectos que resulten de dicha cancelación.
    </p>
    <h3>7.12.</h3>
    <p>
        El acceso y uso del sitio web, efectuada por menores de edad o personas que carezcan de plena capacidad de
        acuerdo a la legislación vigente, es de responsabilidad de sus respectivos padres o representantes legales.
    </p>
    <h3>7.13.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        actúa con altos niveles de seguridad en la protección de los datos personales protegidos, procurando aplicar
        todos los sistemas y técnicas adicionales que tenga a su alcance para evitar la pérdida, uso inadecuado,
        alteración, acceso y robo de datos personales otorgados por el Usuario. No obstante, el Usuario debe ser
        consciente de que la seguridad en Internet no es inexpugnable, sin que existan sistemas absolutamente seguros
        para proteger toda la información. En razón de lo anterior, el Usuario entiende que
        <span class="entity-name">{{ companyName }}</span>
        no puede garantizar que la información proporcionada por el Usuario estará completamente protegida.
    </p>
    <h3>7.14.</h3>
    <p>
        <b>Sobre datos derivados de encuestas</b>
        . Si el Usuario decide voluntariamente participar en las encuestas que se realicen en el Sitio Web, autoriza
        expresamente a
        <span class="entity-name">{{ companyName }}</span>
        a utilizar su ID de usuario para acceder a estos datos, los que serán utilizados de manera interna y anónima,
        confidencial y solo con objeto investigativo. Asimismo, las respuestas de la encuesta, que otorguen datos
        sensibles o no, se podrán unir a los datos recolectado desde otros bancos de datos, en Colombia y en el
        extranjero, pertenecientes a instituciones privadas o públicas de los países en los cuales
        <span class="entity-name">{{ companyName }}</span>
        se encuentra disponible. La decisión de participar en las encuestas es completamente voluntaria y no existen
        riesgos asociados a ella. Si el Usuario decide no contestar la encuesta, no habrá ninguna consecuencia negativa
        para él.
    </p>
    <h3>7.15.</h3>
    <p>
        <b>
            Sobre datos derivados de
            <i>Google</i>
        </b>
        .
        <span class="entity-name">{{ companyName }}</span>
        no transmitirá, comercializará, ni publicará la información de los Usuarios obtenida a través de
        <i>Google</i>
        . Esta información es utilizada de manera interna para la autenticación del sitio mediante la cuenta de
        <i>Google</i>
        . Para lo anterior, solo se guardará el ID del Usuario de
        <i>Google</i>
        , de manera de poder asociarlo a la cuenta de
        <span class="entity-name">{{ companyName }}</span>
        , permitiendo la autenticación del sitio.
    </p>

    <h2>8.- Uso de cookies</h2>
    <h3>8.1.</h3>
    <p>
        Se entiende por Cookie un pequeño archivo que es descargado y almacenado en el disco duro de la computadora o
        dispositivo electrónico del Usuario que contiene caracteres y especificaciones particulares del Usuario, así
        como también, información sobre el uso que el Usuario le da al sitio.
    </p>
    <h3>8.2.</h3>
    <p>
        <span class="entity-name">{{ companyName }}</span>
        puede utilizar cookies, permitiendo su descarga al disco duro del Usuario, para permitir una navegación más
        expedita y personalizada al Usuario.
    </p>
    <h3>8.3.</h3>
    <p>
        El Usuario podrá configurar su navegador para evitar la instalación de cookies en su disco duro. De lo
        contrario, siempre tendrá la opción de explorar su disco duro y conocer en detalle los servidores desde los
        cuales se reciben las cookies, pudiendo optar por eliminarlos.
    </p>

    <h2>9.- Legislación, domicilio y forma especial de notificación.</h2>
    <h3>9.1.</h3>
    <p>
        El presente acuerdo se rige por el ordenamiento jurídico de la República de Colombia, no obstante, el respeto de
        la Ley chilena, en el tratamiento de datos efectuado por O.G.R. S.A., en todo lo que no contradiga la ley
        colombiana. Cualquier controversia derivada del presente acuerdo será sometida a las leyes aplicables en
        Colombia y a los Tribunales de Justicia competentes para su conocimiento, fijando las partes para todos los
        efectos legales su domicilio en la ciudad de Bogotá.
    </p>
    <h3>9.2.</h3>
    <p>
        No obstante lo indicado en el Párrafo anterior, nuestras prácticas y tratamiento de datos personales están
        sujetas a las leyes vigentes de los lugares donde operamos. Esto significa que aplicamos las prácticas descritas
        en el presente documento en un país o en una región solo en el caso de que sus leyes lo permitan.
    </p>
    <h3>9.3.</h3>
    <p>
        Todas las notificaciones y comunicaciones que deba efectuar el Usuario a
        <span class="entity-name">{{ companyName }}</span>
        serán consideradas válidas, para todos los efectos legales, judiciales y contractuales, cuando se dirijan al
        encargado y responsable del tratamiento de datos, Open Green Road S.A. de la siguiente forma: (a) El envío por
        correo postal a la siguiente dirección: Avenida Providencia 187, comuna de Providencia, ciudad de Santiago,
        República de Chile; (b) Para hacer efectivos derechos relacionados al Tratamiento de datos personales, los
        titulares pueden escribir al correo electrónico: contacto@ogr.cl. En tanto, cuando las comunicaciones deban ser
        enviadas al encargado de tratamiento de datos, Open Green Road Colombia SAS, se deberá efectuar de la siguiente
        forma: (a) El envío por correo postal a la siguiente dirección: CL 93, 13-42, oficina 306, Bogotá, Colombia; (b)
        Para hacer efectivos derechos relacionados al Tratamiento de datos personales, los titulares pueden escribir al
        correo electrónico: contacto@ogr.cl
    </p>
    <h3>9.4.</h3>
    <p>
        Todas las notificaciones y comunicaciones que deba efectuar
        <span class="entity-name">{{ companyName }}</span>
        al Usuario serán consideradas válidas, para todos los efectos legales, judiciales y contractuales, cuando se
        dirijan a éste de las siguientes formas: (a) El envío por correo electrónico a cualquiera de las cuentas que el
        Usuario haya puesto a disposición de
        <span class="entity-name">{{ companyName }}</span>
        al momento del registro o con posterioridad.
    </p>
    <h3>9.5.</h3>
    <p>
        Para los efectos de las notificaciones, el Usuario declara y manifiesta que todos los datos otorgados por él son
        ciertos y correctos, comprometiéndose a comunicar a
        <span class="entity-name">{{ companyName }}</span>
        cualquier modificación del correo electrónico disponible para efectuar las notificaciones.
    </p>
</div>
