import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas, Asignatura, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { last, first } from "rxjs/operators"

@Component({
    templateUrl: "home_saber11.component.html",
    styleUrls: ["home_saber11.component.scss"]
})
export class HomeSaber11Component extends MenuComponent {
    menuItemsCol1: MenuItemBoxData[] = []
    menuItemsCol2: MenuItemBoxData[] = []
    disableMenuItem: boolean = false
    config: typeof config = config
    asignaturas: AsignaturaWithConfig[]

    constructor(
        protected store: Store<State>,
        protected grupoUsuariosService: GrupoUsuarios,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.getGrupoUsuarios()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.asignaturas = asignaturasByEvaluacionTipo["saber11"]

        this.menuItemsCol1 = [
            {
                route: "/saber11/generar_simulacro/" + asignaturasByEvaluacionTipo["saber11"][0].id,
                params: { tipo_instrumento: "saber11" },
                label: "Realizar Simulacro Saber 11 por asignatura",
                text: "Realiza simulacros por asignatura similares a los Simulacros Saber 11.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/saber11/resultados/",
                params: { tipo_instrumento: "saber11", asignatura_id: asignaturasByEvaluacionTipo["saber11"][0].id },
                label: "Historial por asignatura",
                text: "Lista de todos los simulacros que has realizado por asignatura.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber11/compartidos/asignatura",
                params: { tipo_instrumento: "saber11", asignatura_id: asignaturasByEvaluacionTipo["saber11"][0].id },
                label: "Historial de simulacros compartidos por asignatura",
                text: "Lista de todos los simulacros por asignatura que te han compartido.",
                linkText: "Ver historial",
                icon: "guia",
                locked: this.disableMenuItem,
                lockedText:
                    "Si tu colegio tiene contratado los servicios de " +
                    this.config.plataforma.info.companyName +
                    ", en esta sección encontrarás la lista de todos los simulacros que te han compartido tus profesores."
            },
            {
                route: "/saber11/mi_progreso/",
                params: { tipo_instrumento: "saber11" },
                label: "Mi Progreso",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "estadistica-o"
            },
            {
                route: "/saber11/estadisticas/",
                params: { tipo_instrumento: "saber11" },
                label: "Estadísticas por asignatura",
                text: "Estadísticas generadas a partir de tus resultados en los simulacros Saber 11 por asignatura.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]

        this.menuItemsCol2 = [
            /*{
			route: '/saber11/generar_simulacro/',
			params: {tipo_instrumento: 'saber11'},
			label: "Realizar simulacro Saber 11",
			text: "Realiza simulacros de nuestra lista de simulacros seleccionados.",
			linkText: "Comenzar",
			icon: "hacer-prueba"
		},{
			route: '/saber11/generar_simulacro/',
			params: {tipo_instrumento: 'saber11'},
			label: "Historial",
			text: "Lista de todos los simulacros que has realizado.",
			linkText: "Ver historial",
			icon: "guia"
		},*/ {
                route: "/saber11/compartidos",
                params: { tipo_instrumento: "simulacro saber11" },
                label: "Historial de simulacros compartidos",
                text: "Lista de todos los simulacros que te han compartido.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber11/recomendados",
                params: { tipo_instrumento: "simulacro saber11" },
                label: "Simulacros recomendados",
                text: "Realiza simulacros completamente recomendados por Puntaje Nacional.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            }
        ]
    }

    getGrupoUsuarios() {
        let usuario = this.authService.getUserData()
        let params = {
            establecimiento_id_not_nil: 1,
            propios: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 0,
                usuario_id: usuario.id
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.disableMenuItem = grupoUsuarios.length <= 0
            this.setMenuItems()
        })
    }
}
