import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    UsuariosCompletarRegistroPuntajeForm,
    Lugares,
    Establecimientos
} from "@puntaje/puntaje/api-services"
import { AuthService, SessionService, BaseForm } from "@puntaje/shared/core"

import { BaseRegisterCompletarComponent } from "./base_register_completar.component"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "usuario-completar-registro-puntaje-colombia",
    templateUrl: "register_completar_puntaje_colombia.component.html",
    styleUrls: ["base_register_completar.component.scss"]
})
export class RegisterCompletarPuntajeColombiaComponent extends BaseRegisterCompletarComponent {
    formType: typeof BaseForm = UsuariosCompletarRegistroPuntajeForm

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        protected lugaresService: Lugares,
        protected establecimientosService: Establecimientos
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            lugaresService,
            establecimientosService
        )
        this.formType = UsuariosCompletarRegistroPuntajeForm
        this.params = this.formType.formParams
        this.usuarioPaisParams = this.params["usuario_" + this.pais].class.formParams
    }

    whereGetEstablecimientos(establecimientoFilter: string): any {
        return {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: establecimientoFilter, lugar_id: this.lugarColegio.id },
            include: `[establecimiento_${this.pais}]`
        }
    }
}
