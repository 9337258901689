import { UniversidadComponent, BuscadorUniversidadesComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "universidad/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UniversidadComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { showTitle: true }
            }
        ]
    },
    {
        path: "buscador-universidades",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BuscadorUniversidadesComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const universidadRoutes = RouterModule.forChild(routes)
