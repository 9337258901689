import { PuntajeDesafiosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { routing } from "./alumnos_desafiosaber11.routing"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

@NgModule({
    imports: [CommonModule, AlumnosLayoutsModule, routing, PuntajeDesafiosModule],
    declarations: [],
    exports: []
})
export class AlumnosDesafiosaber11Module {}
