import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType,
    ComponentsMap,
    CarrerasConf,
    RegistroConf,
    PlanesEstudioConf
} from "@puntaje/shared/core"

export class PuntajeColombiaConfig implements AppConfiguration {
    app = {
        assets: {
            logoLarge: "assets/img/logos/LogoPuntajeNacionalColombiaLarge.webp",
            logoMedium: "assets/img/logos/LogoPuntajeNacionalColombiaMedium.webp",
            logoSmall: "assets/img/logos/LogoPuntajeNacionalColombiaSmall.webp",
            logoLargeLight: "assets/img/logos/LogoPuntajeNacionalColombiaLargeLight.webp",
            logoMediumLight: "assets/img/logos/LogoPuntajeNacionalColombiaMediumLight.webp",
            logoSmallLight: "assets/img/logos/LogoPuntajeNacionalColombiaSmallLight.webp",
            logoSquare: "assets/img/logos/LogoPuntajeNacionalColombiaSquare.webp",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "puntaje",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        }
    } as AppType

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: false
    }

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas: AsignaturaType[] = []

    plataforma = {
        name: "Puntaje Nacional Colombia",
        id: null,
        pais: "colombia",
        lugarTipo: "Municipio",
        lugarLabel: "Municipio",
        identificadorUsuario: ["TI", "CC", "usuario_id"],
        identificadorUsuarioAlias: {
            ti: "TI",
            cc: "CC",
            usuario_id: "ID"
        },
        identificadorEstablecimiento: ["icfes_id"],
        aliasIdentificadorHojaRespuesta: "TI/CC",
        grupoUsuarioAlias: "nivel",
        info: {
            social: {
                facebook: "https://www.facebook.com/puntajenacional.co/",
                twitter: "https://twitter.com/puntajecolombia",
                youtube: "https://www.youtube.com/c/PuntajeNacionalColombia",
                instagram: "https://www.instagram.com/puntajenacionalco",
                twitch: "https://www.twitch.tv/puntajenacionalcolombia"
            },
            soporte: {
                mail: "contacto@ogr.cl",
                telefono: "(+57) 316 6784207<br />(+56) 945387501"
            },
            contacto: {
                telefono: "6317372",
                mail: "ventas@ogr.cl"
            },
            companyName: "PuntajeNacional.co",
            companySite: "www.puntajenacional.co"
        },
        evaluacionDefault: "saber11",
        resumenGeneral: {
            curriculum: "saber",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: ["saber11"],
        evaluacionTipos: ["saber", "saber11", "simulacro saber11"],
        evaluacionTiposMenu: ["saber11"],
        evaluacionTiposMultiples: ["simulacro saber11"],
        añoInicial: 2018,
        periodos: [
            {
                label: "Primer periodo ",
                fechaInicial: [0, 1],
                fechaFinal: [5, 30, 23, 59, 59]
            },
            {
                label: "Segundo periodo ",
                fechaInicial: [6, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: "saber11",
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },

        instrumentoMensualTipo: "saber11",
        hideReforzamientos: true,
        clasificacionTipoNivel: "nivel colombia",
        urlHojaRespuesta:
            "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/latex/hoja_puntaje_nacional_colombia_8_alt_2022.pdf",
        canTextHighlight: true,
        enableAchievements: true,
        planClasesRoute: "/plan_clases",
        planClasesRouteDocentes: "/plan_clases",
        locale: "es-pn-co",
        hideReplicarPlan: true
    } as PlataformaType

    evaluaciones = {
        saber: {
            alias: "simulacro saber",
            clasificaciones: {
                estadisticas: ["componente"],
                groupBy: ["componente"],
                estadisticasEvolutivas: "componente",
                desempenoEvaluacion: "componente",
                clasificacionTiposTablaEspecificaciones: ["componente", "competencia"],
                configuration: [
                    {
                        clasificacionTipo: "componente",
                        name: "Componentes",
                        htmlId: "componente",
                        hasHijos: false
                    }
                ]
            },
            clasificacionDistribucion: "componentes",
            clasificacionMaterial: {
                clasificacion: "componente",
                label: "componente"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "componente",
                    compuesto: false,
                    alumnos: true,
                    profesores: true
                },
                {
                    nombre: "competencia",
                    compuesto: true,
                    alumnos: false,
                    profesores: true
                }
            ],
            cursos: [
                { clasificacion: "Tercero" },
                { clasificacion: "Cuarto" },
                { clasificacion: "Quinto" },
                { clasificacion: "Sexto" },
                { clasificacion: "Septimo" },
                { clasificacion: "Octavo" },
                { clasificacion: "Noveno" },
                { clasificacion: "Decimo" },
                { clasificacion: "Once" }
            ],
            cursosMaterial: [
                { clasificacion: "Tercero" },
                { clasificacion: "Quinto" },
                { clasificacion: "Noveno" },
                { clasificacion: "Once" }
            ],
            distribucionTramoPuntaje: {},
            estrellas: [
                { min: 0, max: 75 },
                { min: 75, max: 150 },
                { min: 150, max: 225 },
                { min: 225, max: 300 },
                { min: 300, max: 375 },
                { min: 375, max: 450 }
            ],
            nivelTipoInstrumentoInformeFinal: ["Tercero", "Quinto", "Noveno", "Once"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 25,
                max: 400
            },
            streamingChannelAlias: "Clases Simulacros",
            cantidadPreguntas: 4,
            cursoTipo: "nivel colombia"
        },

        saber11: {
            alias: "simulacro saber",
            clasificaciones: {
                clasificacionTiposMaterialPlanClase: [{ clasificacion: "componente", label: "componente" }],
                estadisticas: ["competencia", "componente"],
                groupBy: ["competencia", "componente"],
                desempenoEvaluacion: "componente",
                estadisticasEvolutivas: "componente",
                clasificacionTiposTablaEspecificaciones: ["componente", "competencia"],
                configuration: [
                    {
                        clasificacionTipo: "competencia",
                        name: "Competencias",
                        htmlId: "competencia",
                        hasHijos: false
                    },
                    {
                        clasificacionTipo: "componente",
                        name: "Componentes",
                        htmlId: "componente",
                        hasHijos: false
                    }
                ]
            },
            clasificacionDistribucion: "competencias",
            clasificacionMaterial: {
                clasificacion: "componente",
                label: "componente"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "componente",
                    compuesto: false,
                    alumnos: true,
                    profesores: true
                },
                {
                    nombre: "competencia",
                    compuesto: true,
                    alumnos: false,
                    profesores: true
                }
            ],
            cursos: [{ clasificacion: "Once" }],
            cursosMaterial: [{ clasificacion: "Once" }],
            distribucionTramoPuntaje: {},
            estrellas: [
                { min: 0, max: 16 },
                { min: 17, max: 33 },
                { min: 34, max: 50 },
                { min: 51, max: 67 },
                { min: 68, max: 84 },
                { min: 85, max: 100 }
            ],
            nivelTipoInstrumentoInformeFinal: ["Once"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 10,
                max: 100
            },
            streamingChannelAlias: "Clases Simulacros",
            cantidadPreguntas: 4,
            cursoTipo: "nivel colombia",
            planPersonalClasificacionMateriales: [{ clasificacion: "componente", label: "componente" }]
        },

        "simulacro saber11": {
            alias: "simulacro saber 11",
            clasificaciones: {
                estadisticas: ["competencia", "componente"],
                groupBy: ["competencia", "componente"],
                clasificacionTiposTablaEspecificaciones: ["componente", "competencia"],
                configuration: [
                    {
                        clasificacionTipo: "competencia",
                        name: "Competencias",
                        htmlId: "competencia",
                        hasHijos: false
                    },
                    {
                        clasificacionTipo: "componente",
                        name: "Componentes",
                        htmlId: "componente",
                        hasHijos: false
                    }
                ],
                desempenoEvaluacion: "componente"
            },
            clasificacionDistribucion: "competencias",
            clasificacionMaterial: {
                clasificacion: "componente",
                label: "componente"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "componente",
                    compuesto: true,
                    alumnos: true,
                    profesores: true
                },
                {
                    nombre: "competencia",
                    compuesto: true,
                    alumnos: false,
                    profesores: true
                },
                {
                    nombre: "Afirmación_CO",
                    profesores: true,
                    alumnos: false,
                    tabla: true
                },
                {
                    nombre: "Evidencia_CO",
                    profesores: true,
                    alumnos: false,
                    tabla: true
                }
            ],
            cursos: [
                { clasificacion: "Tercero" },
                { clasificacion: "Cuarto" },
                { clasificacion: "Quinto" },
                { clasificacion: "Sexto" },
                { clasificacion: "Septimo" },
                { clasificacion: "Octavo" },
                { clasificacion: "Noveno" },
                { clasificacion: "Decimo" },
                { clasificacion: "Once" }
            ],
            cursosMaterial: [
                { clasificacion: "Tercero" },
                { clasificacion: "Quinto" },
                { clasificacion: "Noveno" },
                { clasificacion: "Once" }
            ],
            distribucionTramoPuntaje: {},
            estrellas: [],
            nivelTipoInstrumentoInformeFinal: ["Tercero", "Quinto", "Noveno", "Once"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 10,
                max: 100
            },
            streamingChannelAlias: "Clases Simulacros",
            cantidadPreguntas: 4,
            cursoTipo: "nivel colombia"
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4]
        ],
        sectoresByMarkers: {
            7: [
                [1, 2],
                [2, 3],
                [3, 4],
                [5, 2],
                [2, 7],
                [7, 4]
            ],
            16: [
                [1, 2],
                [2, 3],
                [4, 2],
                [2, 6],
                [4, 5],
                [5, 6],
                [7, 5],
                [5, 9],
                [7, 8],
                [10, 11],
                [12, 13],
                [12, 16]
            ]
        },
        alternativas: 8,
        alternativasByMarkers: {
            7: 8,
            16: 4
        },
        rutFormaByMarkers: {
            7: {
                rutMarkerUp: 6,
                rutMarkerBottom: 7,
                rutCols: 10,
                formaMarkerUp: 5,
                formaMarkerBottom: 6,
                formaCols: 10
            },
            16: {
                rutMarkerUp: 14,
                rutMarkerBottom: 15,
                rutCols: 11,
                formaMarkerUp: 15,
                formaMarkerBottom: 16,
                formaCols: 11
            }
        },
        alignByMarkers: {
            7: [1, 5],
            17: [1, 4]
        },
        dimSectoresByMarkers: {
            7: {
                "1,2": { cols: 8, rows: 10 },
                "2,3": { cols: 8, rows: 10 },
                "3,4": { cols: 8, rows: 10 },
                "5,2": { cols: 8, rows: 10 },
                "2,7": { cols: 8, rows: 10 },
                "7,4": { cols: 8, rows: 10 },
                "5,6": { cols: 10, rows: 10 },
                "6,7": { cols: 10, rows: 10 }
            },
            16: {
                "1,2": { cols: 4, rows: 10 },
                "2,3": { cols: 4, rows: 10 },
                "4,2": { cols: 4, rows: 10 },
                "2,6": { cols: 4, rows: 10 },
                "4,5": { cols: 4, rows: 10 },
                "5,6": { cols: 4, rows: 10 },
                "7,5": { cols: 4, rows: 10 },
                "5,9": { cols: 4, rows: 9 },
                "7,8": { cols: 7, rows: 6 },
                "10,11": { cols: 4, rows: 5 },
                "12,13": { cols: 4, rows: 22 },
                "12,16": { cols: 4, rows: 22 },
                "14,15": { cols: 11, rows: 10 },
                "15,16": { cols: 11, rows: 10 }
            }
        },
        validMarkers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o TI/CC",
        invalidLoginId: "Debes incluir un correo electrónico o TI/CC válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o TI-CC/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }

    carreras = {
        criteriaFilter: [
            {
                filter: "Tipo de acreditacion",
                tipo: "tipo_acreditacion",
                value: [],
                visible: false
            },
            {
                filter: "Modalidad",
                tipo: "modalidad",
                value: [],
                visible: false
            },
            {
                filter: "Área",
                tipo: "area",
                value: [],
                visible: false
            },
            {
                filter: "Nivel de formación",
                tipo: "nivel_formacion",
                value: [],
                visible: false
            },
            {
                filter: "Periodicidad",
                tipo: "periodicidad",
                value: [],
                visible: false
            },
            {
                filter: "Dependencia",
                tipo: "dependencia",
                value: [],
                visible: false
            }
        ]
    } as CarrerasConf

    mensajesInformativos = {
        problemasPlataforma: ``
    }

    componentsInSubmodule = {
        subirPruebaPropia: {
            skipContestableTipos: false
        }
    } as ComponentsMap

    registro = {
        enablePoliticasPrivacidad: true
    } as RegistroConf

    planesEstudio = {
        hidePlanesAdaptive: true
    } as PlanesEstudioConf
}
