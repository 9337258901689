import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { AppConfig, I18nService } from "@puntaje/shared/core"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { ActivatedRoute, Router } from "@angular/router"
import { first, filter } from "rxjs/operators"
import { Subscription } from "rxjs"
import { Store, select } from "@ngrx/store"
import { State, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"

declare const config: AppConfig

@Component({
    selector: "practicar",
    templateUrl: "./practicar.component.html"
})
export class PracticarComponent extends MenuComponent implements OnInit {
    config: typeof config
    asignaturas: Asignatura[]
    menuReady: boolean = false
    itemsMenu: any = []
    linkEvaluacion: any = ["/saber11", "generar_simulacro"]

    sub: Subscription
    tipoInstrumento: string

    subAlias: Subscription
    evaluacionTipoAliases: any

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected translatorService: I18nService,
        protected router: Router,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"] || config.plataforma.evaluacionDefault
        })

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAliases = evaluacionTipoAliases
        })

        this.config = config
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.asignaturas = asignaturasByEvaluacionTipo["saber11"]

        this.itemsMenu = [
            {
                route: "/saber11/generar_simulacro/" + asignaturasByEvaluacionTipo["saber11"][0].id,
                params: { evaluacion_tipo: "saber11" },
                name: "Realizar Simulacro " + this.evaluacionTipoAliases["saber11"] + " personalizado"
            },
            {
                route: "/saber11/recomendados",
                params: { tipo_instrumento: "simulacro saber11" },
                name: "Hacer Simulacros " + this.evaluacionTipoAliases["simulacro saber11"] + " recomendados"
            },
            {
                route: "/saber11/recomendados_por_asignatura",
                params: { tipo_instrumento: "saber11" },
                name: "Hacer Simulacro " + this.evaluacionTipoAliases["saber11"] + " por componente"
            }
        ]
        this.menuReady = true
        this.linkEvaluacion = ["/saber11", "generar_simulacro", asignaturasByEvaluacionTipo["saber11"][0].id]
    }
}
