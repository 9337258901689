import { Component, Input, OnInit } from "@angular/core"

@Component({
    selector: "calendario-streaming-showcasebox",
    templateUrl: "./calendario-streaming-showcasebox.component.html",
    styleUrls: ["./calendario-streaming-showcasebox.component.scss"]
})
export class CalendarioStreamingShowcaseboxComponent implements OnInit {
    @Input() horarioStreaming: string =
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/home/horario+streaming.png"

    constructor() {}

    ngOnInit() {}
}
