<div class="content">
    <h3>1. OBJETIVO</h3>
    <p>
        El objetivo de la presente Política de Privacidad y Protección de Datos Personales, (en adelante “Política”), es
        dar
        cumplimiento a la Ley Estatutaria No. 1581 de 2012, su Decreto Reglamentario 1074 de 2015 (Capítulo 25) y demás
        normas
        que rigen la Protección de Datos Personales, o aquellas que las complementen, sustituyan, modifiquen o deroguen
        y en
        especial, garantizar el derecho de Hábeas Data de los Titulares de Datos Personales. En cumplimiento de las
        normas
        vigentes y principios rectores, el Responsable del Tratamiento únicamente recolectará y tratará los Datos
        Personales que
        sean estrictamente necesarios para las finalidades establecidas en la presente Política y/o en los Avisos de
        Privacidad
        y que hayan sido puestos en conocimiento de los Titulares.
    </p>

    <h3>2. ALCANCE</h3>
    <p>
        La presente Política es aplicable tanto a OPEN GREEN ROAD COLOMBIA S.A.S., en calidad de Responsable del
        Tratamiento y a
        sus empleados directos e indirectos, como a todas aquellas terceras personas naturales o jurídicas a quienes
        Transmita o
        Transfiera Datos Personales de los Titulares que comprenden los Grupos de Interés del Responsable del
        Tratamiento,
        cuando estos realicen algún Tratamiento sobre los mismos.
    </p>

    <h3>3. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO</h3>
    <p>
        NOMBRE: OPEN GREEN ROAD COLOMBIA S.A.S.
        <br />
        CORREO ELECTRÓNICO: sebastian.arancibia@ogr.cl
    </p>

    <h3>4. DEFINICIONES</h3>
    <p>
        Para los efectos de la presente Política, se entenderá por:
        <br />
        <b>ADOLESCENTE:</b>
        Personas entre 12 y 18 años de edad (Código de la Infancia y de la Adolescencia, artículo 3).
        <br />
        <b>AUTORIZACIÓN:</b>
        Consentimiento previo, expreso e informado del Titular de Datos Personales para llevar a cabo el Tratamiento de
        sus
        datos personales, la cual puede ser recolectada de manera (i) escrita, (ii) oral o (iii) mediante conductas
        inequívocas,
        que permitan concluir de manera razonable que este otorgó la autorización.
        <br />
        <b>AVISO DE PRIVACIDAD:</b>
        Documento físico, electrónico o en cualquier otro formato generado por el Responsable del Tratamiento, que se
        pone a
        disposición del Titular para el Tratamiento de sus datos personales. En el Aviso de Privacidad se comunica al
        Titular la
        información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la
        forma de
        acceder a las mismas y las características del tratamiento que se pretende dar a los Datos Personales.
        <br />
        <b>BASE DE DATOS:</b>
        Conjunto organizado de datos personales físico o electrónico (digital) que sea objeto de Tratamiento manual o
        automatizado.
        <br />
        <b>DATOS PERSONALES:</b>
        Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o
        determinables. La
        naturaleza de los Datos Personales puede ser pública, semiprivada, privada o sensible.
        <br />
        <b>DATO PRIVADO:</b>
        Es el dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.
        <br />
        <b>DATO PÚBLICO:</b>
        Es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y aquel que no sea
        semiprivado, privado o sensible. Son públicos, entre otros, los datos relativos al estado civil de las personas,
        a su
        profesión u oficio, a su calidad de comerciante o de servidor público y aquellos que puedan obtenerse sin
        reserva
        alguna. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos,
        documentos
        públicos, gacetas y boletines oficiales, que no estén sometidos a reserva.
        <br />
        <b>DATOS SENSIBLES:</b>
        Son aquellos que afectan la intimidad del Titular de Datos Personales o cuyo uso indebido puede generar su
        discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las
        convicciones
        religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que
        promueva
        intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de
        oposición así
        como los datos relativos a la salud, a la vida sexual y los datos biométricos (huella dactilar, el iris del ojo,
        voz,
        forma de caminar, palma de la mano o los rasgos del rostro, fotografías, videos, entre otros). A los Datos
        Personales de
        Niños, Niñas y/o Adolescentes, se les aplicarán las mismas normas y procedimientos que a los Datos Sensibles, y
        no se le
        dará Tratamiento alguno que pueda vulnerar o amenazar su desarrollo físico, mental y emocional.
        <br />
        <b>DATOS SEMIPRIVADOS:</b>
        Son aquellos que no tienen una naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede
        interesar no solo a su titular, sino a un grupo de personas o a la sociedad en general. Se entiende por dato
        semiprivado, entre otros, la información relacionada con seguridad social y con el comportamiento financiero y
        crediticio.
        <br />
        <b>DERECHO DE HÁBEAS DATA:</b>
        Conforme a lo dispuesto por el artículo 15 de la Constitución Política de Colombia, es el derecho que tienen
        todas las
        personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bancos de
        datos y en
        archivos de entidades públicas y privadas. Dentro del Derecho de Hábeas Data, se encuentran los siguientes:
        <br />
        <b>- Derecho de acceso o consulta:</b>
        Derecho que tiene el Titular a ser informado por el Responsable del Tratamiento, o los Encargados del
        Tratamiento,
        previa solicitud, respecto de qué Datos Personales se encuentran incluidos en las Bases de Datos del Responsable
        del
        Tratamiento, origen, uso y finalidad que se le han dado a los mismos.
        <br />
        <b>- Reclamo de corrección o actualización:</b>
        Derecho del Titular a que se actualicen, rectifiquen o modifiquen aquellos datos parciales, inexactos,
        incompletos,
        fraccionados, que induzcan a error.
        <br />
        <b>- Reclamo de supresión:</b>
        Derecho del Titular a que se supriman los datos que resulten inadecuados, excesivos o que no respeten los
        principios,
        derechos y garantías constitucionales y legales o aquellos cuyo Tratamiento esté expresamente prohibido o no
        haya sido
        autorizado. La solicitud de supresión de la información no procederá cuando el Titular tenga un deber legal o
        contractual de permanecer en la Base de Datos.
        <br />
        <b>- Reclamo de revocación de la autorización:</b>
        Derecho del Titular a dejar sin efecto la autorización previamente prestada para el tratamiento de sus datos
        personales.
        La revocatoria de la autorización no procederá cuando el Titular tenga un deber legal o contractual de
        permanecer en la
        Base de Datos.
        <br />
        <b>- Reclamo de infracción:</b>
        Derecho del Titular a solicitar que se subsane el incumplimiento de la normativa en materia de Protección de
        Datos
        Personales.
        <br />
        <b>- Consulta de la autorización para el Tratamiento:</b>
        Derecho del Titular a solicitar prueba de la Autorización otorgada para el Tratamiento de sus Datos Personales.
        <br />
        <b>- Queja ante la SIC:</b>
        Derecho a presentar quejas ante la Superintendencia de Industria y Comercio por infracciones en materia de
        Protección de
        Datos Personales, una vez agotado el trámite de consulta o reclamo ante el responsable del tratamiento o
        encargado del
        tratamiento.
        <br />
        <br />
        <b>ENCARGADO DEL TRATAMIENTO:</b>
        Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de
        Datos
        Personales por cuenta del Responsable del Tratamiento. Para los efectos de la presente Política, se entienden
        como
        Encargados del Tratamientos los reportados en el Registro Nacional de Bases de Datos.
        <br />
        <b>GRUPOS DE INTERÉS:</b>
        Para los efectos de la presente Política, se entenderán como Grupos de Interés todos los grupos de personas
        naturales,
        usuarias o potenciales, de las diferentes plataformas del Responsable del Tratamiento.
        <br />
        <b>NIÑO O NIÑA:</b>
        Personas entre los 0 y 12 años (Código de la Infancia y de la Adolescencia, artículo 3). OFICIAL DE PROTECCIÓN
        DE DATOS
        PERSONALES: Persona o Área Responsable de atender las Quejas y Reclamos que se presenten en materia de
        Protección de
        Datos Personales, designada en la Política.
        <br />
        <b>PLATAFORMAS:</b>
        Sistemas operativos o herramientas tecnológicas de propiedad del Responsable del Tratamiento o de propiedad de
        terceros,
        cuando quiera que este ostente la calidad de Responsable del Tratamiento, en las cuales se Traten Datos
        Personales de
        los diferentes Grupos de Interés y los Titulares. PQR ́S: Consultas y reclamos en materia de Protección de Datos
        Personales.
        <br />
        <b>Clases de PQR’S</b>
        <br />
        <b>Consulta:</b>
        Petición de información presentada por el Titular, sus causahabientes o apoderado, por los medios establecidos
        para
        ello, tendiente a conocer la información que de éste se posee en las bases de datos administradas por el
        Responsable del
        Tratamiento, origen, uso y finalidad que se le han dado a los mismos.
        <br />
        <b>Reclamo:</b>
        Solicitud presentada por el titular, sus causahabientes o apoderado por los medios establecidos para ello,
        cuando
        considere que la información contenida en una base de datos administrada por el Responsable del Tratamiento,
        debe ser
        sujeta de corrección, actualización, rectificación o supresión o cuando se pretenda revocar la autorización.
        <br />
        <br />
        <b>PROTECCIÓN DE DATOS:</b>
        son todas las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los
        registros
        evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
        <br />
        <b>RESPONSABLE DEL TRATAMIENTO:</b>
        Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de
        Datos y/o el Tratamiento de los datos.
        <br />
        <br />
        Para los efectos de la presente Política, se entiende como Responsable del Tratamiento OPEN GREEN ROAD COLOMBIA
        S.A.S.
        <br />
        <br />
        <b>TITULAR:</b>
        Para los efectos de las diferentes plataformas, serán los estudiantes, representantes legales y/o acudientes y
        docentes.
        <br />
        <b>TRANSFERENCIA:</b>
        La Transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos personales,
        ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es Responsable del
        Tratamiento y se encuentra dentro o fuera del país.
        <br />
        <b>TRANSMISIÓN:</b>
        Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del territorio de la
        República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del
        Responsable.
        <br />
        <b>TRATAMIENTO:</b>
        Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento,
        actualización, uso, circulación, Transferencia, Transmisión o supresión. USUARIO: cualquier persona natural o en
        representación de una persona jurídica que ingrese a las Plataformas y navegue en las mismas y acceda a las
        diferentes funcionalidades que estas ofrecen.
    </p>

    <h3>5. PRINCIPIOS RECTORES</h3>
    <p>
        Los siguientes, son los Principios Rectores en materia de Protección de Datos Personales, y aplicarán al
        Tratamiento que realicen el Responsable de Tratamiento, sus empleados y todas aquellas terceras personas
        naturales o jurídicas a quienes Transmita o Transfiera Datos Personales de los Titulares que comprenden sus
        Grupos de Interés, cuando estos realicen algún Tratamiento sobre los mismos:
        <br />
        <b>PRINCIPIO DE LEGALIDAD:</b>
        El Tratamiento a los Datos Personales se realizará de acuerdo con los requerimientos legales establecidos en la
        Ley Estatutaria 1581 de 2012 y sus decretos reglamentarios.
        <br />
        <b>PRINCIPIO DE FINALIDAD:</b>
        El Tratamiento de los Datos Personales debe obedecer a una finalidad legítima de acuerdo con la constitución y
        la Ley, la cual debe ser informada al Titular.
        <br />
        <b>PRINCIPIO DE LIBERTAD:</b>
        El Tratamiento sólo puede llevarse a cabo con el consentimiento, previo, expreso e informado del Titular. Los
        datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o
        judicial que releve el consentimiento. Quedan exceptuados de este principio los Datos Públicos, los cuales
        podrán ser objeto de Tratamiento sin que se requiera autorización del Titular, conforme a lo dispuesto por la
        Ley 1581 de 2012 y su Decreto Reglamentario 1074 de 2015.
        <br />
        <b>PRINCIPIO DE VERACIDAD O CALIDAD:</b>
        La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible.
        Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.
        <br />
        <b>PRINCIPIO DE TRANSPARENCIA:</b>
        En el Tratamiento debe garantizarse el derecho del Titular a obtener en cualquier momento y sin restricciones,
        información acerca de la existencia de datos que le conciernan.
        <br />
        <b>PRINCIPIO DE ACCESO Y CIRCULACIÓN RESTRINGIDA:</b>
        Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de
        divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento
        restringido sólo a los Titulares o terceros autorizados.
        <br />
        <b>PRINCIPIO DE SEGURIDAD:</b>
        La información sujeta a Tratamiento, se deberá proteger mediante el uso de las medidas técnicas, humanas y
        administrativas que sean necesarias para otorgar seguridad a los registros, evitando su adulteración, pérdida,
        consulta, uso o acceso no autorizado o fraudulento.
        <br />
        <b>PRINCIPIO DE CONFIDENCIALIDAD:</b>
        Todas las personas que intervengan en el Tratamiento de datos personales, están obligadas a garantizar la
        reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende
        el Tratamiento.
    </p>

    <h3>6. TRATAMIENTOS A LOS CUALES SERÁN SOMETIDOS LOS DATOS PERSONALES Y SUS FINALIDADES</h3>
    <p>
        Para los efectos de la presente Política, el Responsable del Tratamiento directamente o a través de Encargados
        del Tratamiento, podrá recolectar, almacenar, usar, circular, actualizar, suprimir o realizar cualquier otro
        tipo de Tratamiento Manual o Automatizado sobre los Datos Personales de sus Grupos de Interés, ajustándose en
        todo momento a lo dispuesto por las normas vigentes y para las finalidades que se describen a continuación:
    </p>

    <h3>
        6.1. FINALIDADES GENERALES PARA EL TRATAMIENTO DE DATOS PERSONALES DE TODOS LOS GRUPOS DE INTERÉS Y TITULARES
    </h3>
    <p>
        • Operar las diferentes Plataformas
        <br />
        • Gestión de registro y parametrización de perfiles.
        <br />
        • Compartir en las Plataformas y/o a través de los datos de contacto suministrados (teléfono fijo y/o móvil,
        correo físico y/o electrónico, mensajes de texto SMS y/o MMS, redes sociales, push notifications, medios
        electrónicos, entre otros), información de interés tanto propia, como de aliados estratégicos o de los mismos
        Usuarios, incluyendo, pero sin limitarse a: notificaciones, ofrecimiento de bienes o servicios propios o de
        terceros, cursos, materialización de encuestas, publicidad, concursos, sorteos, apoyo para obtención de becas o
        subsidios para estudios, alianzas con entidades educativas, reconocimientos por desempeño, marketing,
        promociones, eventos, alianzas y beneficios propias o de aliados estratégicos.
        <br />
        • Transmisión y Transferencia nacional e internacional y almacenamiento y custodia de información y/o Datos
        Personales en archivos físicos o servidores propios y/o de terceros, ubicados dentro o fuera del país, en países
        considerados por la Superintendencia de Industria y Comercio como seguros o aquellos que no lo sean, siempre que
        se requiera para el desarrollo de las actividades propias del Responsable del Tratamiento y las relaciones con
        los diferentes Grupos de Interés, y, para el caso de docentes, con sus empleadores o contratantes.
        <br />
        • Seguimiento y rastreo de las operaciones realizadas a través de las Plataformas.
        <br />
        • Controles, estadísticas e históricos de las relaciones mantenidas con los Titulares.
        <br />
        • Indicadores internos.
        <br />
        • Base para toma de decisiones.
        <br />
        • Soporte en procesos de auditorías internas y/o externas, revisorías fiscales, consultorías e implementación de
        planes de mejora.
        <br />
        • Cumplimiento de normas y obligaciones vigentes.
        <br />
        • Reportes ante autoridades administrativas y judiciales competentes.
        <br />
        • Atención de requerimientos realizados por autoridades administrativas y judiciales competentes.
        <br />
        • Elaboración y presentación de demandas y denuncios ante las autoridades competentes, así como ejercer el
        derecho de defensa en cualquier proceso administrativo y/o judicial.
        <br />
        • Atención de PQR’s.
        <br />
        • Expedición de pólizas y seguros.
        <br />
        • Análisis de comportamiento y segmentación del mercado.
        <br />
        • Inteligencia de negocios.
        <br />
        • Estudios de mercado, análisis estadísticos, investigaciones y publicaciones, reconocimientos, ranking de
        mejores estudiantes
        <br />
        • Actividades de remarketing.
        <br />
        • Fidelización de los Titulares.
        <br />
        • Análisis y elaboración de programas que generen un impacto social para los Grupos de Interés del Responsable
        del Tratamiento.
        <br />
        • Las demás establecidas en los Avisos de Privacidad y las Políticas de Cookies.
    </p>

    <h3>6.2. FINALIDADES ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES DE ESTUDIANTES</h3>
    <p>
        <br />
        • Evaluaciones de conocimiento
        <br />
        • Apoyo para la consecución de becas y/o subsidios educativos.
        <br />
        • Circulación de información con docentes y entidades educativas, para mejoramiento y seguimiento del proceso
        educativo.
    </p>

    <h3>
        6.3. FINALIDADES ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES DE REPRESENTANTES LEGALES Y/O ACUDIENTES
    </h3>
    <p>• Gestión de facturación y pago.</p>

    <h3>6.4. FINALIDADES ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES DE DOCENTES</h3>
    <p>• Seguimiento y asignación de actividades dentro de la plataforma y visualización de resultados.</p>

    <h3>7. DERECHOS DE LOS TITULARES</h3>
    <p>
        Son derechos de los Titulares de Datos Personales, los siguientes:
        <br />
        • Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o Encargados
        del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos,
        fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido
        autorizado.
        <br />
        • Solicitar prueba de la autorización otorgada al Responsable del Tratamiento salvo cuando expresamente se
        exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la Ley 1581 de
        2012.
        <br />
        • Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto al
        uso que le ha dado a sus datos personales.
        <br />
        • Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley
        1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen.
        <br />
        • Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los
        principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la
        Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado ha
        incurrido en conductas contrarias a la Ley 1581 de 2012 y a la constitución.
        <br />
        • Acceder en forma gratuita a los datos personales que hayan sido objeto de Tratamiento. La solicitud de
        supresión de la información y la revocatoria de la autorización no procederán cuando el Titular tenga un deber
        legal o contractual de permanecer en la base de datos.
    </p>

    <h3>8. DEBERES DEL RESPONSABLE DEL TRATAMIENTO</h3>
    <p>
        Es deber del Responsable del Tratamiento:
        <br />
        • Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del Derecho de Hábeas Data.
        <br />
        • Solicitar y conservar por cualquier medio y en las condiciones previstas en la Ley 1581 de 2012, copia de la
        respectiva autorización otorgada por el Titular.
        <br />
        • Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud
        de la autorización otorgada.
        <br />
        • Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida,
        consulta, uso o acceso no autorizado o fraudulento.
        <br />
        • Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta,
        actualizada, comprobable y comprensible.• Actualizar la información, comunicando de forma oportuna al Encargado
        del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las
        demás medidas necesarias para que la información suministrada a este se mantenga actualizada.
        <br />
        • Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.
        <br />
        • Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente
        autorizado de conformidad con lo previsto en la Ley 1581 de 2012.
        <br />
        • Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de
        la información del Titular.
        <br />
        • Tramitar las consultas y reclamos formulados en los términos señalados en la Ley 1581 de 2012.
        <br />
        • Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la Ley
        1581 y en especial, para la atención de consultas y reclamos.
        <br />
        • Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del
        Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.
        <br />
        • Informar a solicitud del Titular sobre el uso dado a sus datos.
        <br />
        • Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y
        existan riesgos en la administración de la información de los Titulares.
        <br />
        • Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
    </p>

    <h3>
        9. DEBERES DE LOS ENCARGADOS DEL TRATAMIENTO Y DEL RESPONSABLE DEL TRATAMIENTO, CUANDO ACTÚE EN CALIDAD DE
        ENCARGADO DEL TRATAMIENTO DE TERCEROS
    </h3>
    <p>
        Es deber del Encargado del Tratamiento:
        <br />
        • Cumplir en el desarrollo de las actividades contratadas, con la presente Política, así como con todos aquellos
        procedimientos, guías y/o directrices que imparta el Responsable del Tratamiento en materia de Protección de
        Datos Personales.
        <br />
        • Adoptar, según instrucciones del Responsable del Tratamiento, todas las medidas técnicas, humanas y
        administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida,
        consulta, uso o acceso no autorizado o fraudulento.
        <br />
        • Implementar una Política de Protección de Datos Personales que se ajuste a lo dispuesto por las normas que
        regulan la materia.
        <br />
        • Dar Tratamiento a los Datos Personales conforme a las instrucciones que reciba expresamente del Responsable
        del tratamiento, absteniéndose de usarlos para fines distintos a los contratados.
        <br />
        • Abstenerse de suministrar, ceder o comercializar los Datos Personales con terceras personas naturales o
        jurídicas, públicas o privadas, salvo que la misma sea de naturaleza pública sin sujeción a reserva, o sea
        requerida por una autoridad competente en el ejercicio de sus funciones legales.• Guardar estricta
        confidencialidad respecto de los datos de carácter personal a que tuvieran acceso en ejercicio de las
        actividades contratadas, así como a cumplir diligentemente el deber de guardia y custodia sobre los mismos
        durante todo el término vigencia del contrato y aún después de producida la terminación.
        <br />
        • Acceder o consultar la información o Datos Personales que reposen en las Bases de Datos del Responsable del
        Tratamiento únicamente cuando sea estrictamente necesario para el ejercicio de las actividades contratadas.
        <br />
        • Reportar al Responsable del Tratamiento de manera inmediata a su materialización o al momento en que llegaren
        a su conocimiento, por los conductos y medios establecidos por este, cualquier incidente o amenaza de incidente
        que afecte o pueda llegar a afectar directa o indirectamente la protección de datos personales.
        <br />
        • Garantizar en todo tiempo, el pleno y efectivo ejercicio del Derecho de Hábeas Data de los Titulares, así como
        el debido proceso en caso de presentarse PQR ́s en materia de Protección de Datos Personales.
        <br />
        • Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de la Ley 1581
        de 2012.
        <br />
        • Actualizar la información reportada por el Responsable del Tratamiento, dentro de los cinco (5) días hábiles
        contados a partir de su recibo.
        <br />
        • Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la Ley
        1581 de 2012 y, en especial, para la atención de consultas y reclamos por parte de los Titulares.
        <br />
        • Abstenerse de circular información que esté siendo controvertida por el Titular y cuyo bloqueo haya sido
        ordenado por la Superintendencia de Industria y Comercio.
        <br />
        • Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.
        <br />
        • Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
        <br />
        • En caso de recolectar datos por cuenta del Responsable del Tratamiento, requerir la autorización de los
        Titulares, en los casos en los que se requiera, conforme a lo dispuesto por la Ley 1581 de 2012, y demás normas
        que la complementen, sustituyan, modifiquen o deroguen.
    </p>

    <h3>10. ÁREA ENCARGADA DE LA ATENCIÓN DE PQR’s</h3>
    <p>
        El área responsable de la atención de peticiones, consultas y reclamos ante la cual el Titular de la información
        puede ejercer sus derechos a conocer, actualizar, rectificar y suprimir el dato y revocar la autorización, será
        Soporte, quien ejercerá, entre otras, las funciones de Oficial de Protección de Datos Personales.
    </p>

    <h3>11. PROCEDIMIENTO PARA QUE LOS TITULARES DE LA INFORMACIÓN PUEDAN EJERCER LOS DERECHOS</h3>
    <p>
        Los Titulares o aquellas personas que se encuentren legitimadas por normas vigentes, pueden presentar
        Peticiones, Consultas y Reclamos a través de los siguientes canales, establecidos por el Responsable del
        Tratamiento para la atención de PQR’s:
        <br />
        Correo electrónico:
        <b>{{ emailContacto }}</b>
        <br />
        Las siguientes, son las personas facultadas para presentar PQR ́s, conforme a lo dispuesto por el artículo
        2.2.2.25.4.1. del Decreto 1074 de 2015:
        <br />
        • El Titular, quien deberá acreditar su identidad en forma suficiente.
        <br />
        • Los causahabientes del Titular, quienes deberán acreditar tal calidad.
        <br />
        • El representante y/o apoderado del Titular, previa acreditación de la representación o apoderamiento.
        <br />
        • Por estipulación a favor de otro o para otro, siempre que medie la aceptación por parte del Titular, de lo
        cual, se deberá presentar constancia en la solicitud. Los derechos de los Niños, Niñas o Adolescentes se
        ejercerán por las personas que estén facultadas para representarlos. Las consultas y reclamos serán resueltos
        dentro de los términos establecidos en las Leyes 1266 de 2008 y 1581 de 2012, o aquellas que las sustituyan,
        modifiquen o deroguen.
    </p>

    <h3>11.1. CONSULTAS</h3>
    <p>
        El Responsable del Tratamiento o el Encargado del Tratamiento, responderá la consulta en un término máximo de
        diez (10) días hábiles contados desde la fecha de recibo de la misma. Si no es posible dar respuesta en este
        lapso, se informará al interesado los motivos de la demora y se señalará la fecha de respuesta que no puede
        superar cinco (5) días hábiles siguientes al primer vencimiento.
    </p>

    <h3>11.2. RECLAMOS</h3>
    <p>
        El Responsable del Tratamiento o el Encargado del Tratamiento, responderá al reclamo dentro de los quince (15)
        días hábiles contados desde el día siguiente al recibo del mismo. Si no es posible dar respuesta en este lapso
        de tiempo, la Compañía informará al interesado los motivos de la demora y señalará la fecha de respuesta, dentro
        de los ocho (8) días hábiles siguientes al primer vencimiento.
    </p>

    <h3>12. ENLACES EXTERNOS</h3>
    <p>
        Nuestras Plataformas, podrán incluir enlaces a otros sitios web a los que los Usuarios voluntariamente podrán
        acceder. Tenga en cuenta que no somos responsables del contenido ni de las prácticas de privacidad de dichos
        sitios. Recomendamos a nuestros Usuarios que estén atentos cuando abandonen nuestras Plataformas y que se
        informen respecto de las políticas de uso y de privacidad de los terceros.
    </p>

    <h3>13. POLÍTICAS DE COOKIES Y TECNOLOGÍAS SIMILARES</h3>
    <p>
        Nuestras Plataformas podrán utilizar cookies propias o de terceros. Por lo general, las cookies utilizadas son
        técnicas (almacenamiento de información de imágenes y colores) caso en el cual no se recolectarán Datos
        Personales. Sin perjuicio de lo anterior, algunas Plataformas podrán utilizar otro tipo de cookies o tecnologías
        similares, a través de las cuales se recolecten y traten Datos Personales. En esos casos específicos, la misma
        plataforma informará sobre la existencia de las mismas, para que el Titular decida, de manera libre e informada,
        si continúa navegando en la Plataforma, aceptando la presente Política y autorizando el Tratamiento sobre sus
        Datos Personales
    </p>

    <h3>13.1. ¿QUÉ ES UNA COOKIE O TECNOLOGÍA SIMILAR?</h3>
    <p>
        Una Cookie es un pequeño archivo de texto que se descarga y almacena en su computador, cuando ingresa a un sitio
        web. Las Cookies son utilizadas para recolectar información suya de manera automática, a través de inteligencia
        artificial (sin necesidad de que usted la suministre directamente).La mayoría de los Navegadores permitirán que
        usted elimine o bloquee las Cookies de su computador o su disco duro o alertará sobre las mismas cuando estas se
        activen. Sin perjuicio de lo anterior, el bloqueo de ciertas cookies, impedirán el uso adecuado de algunas
        funcionalidades. Lo invitamos a remitirse a las instrucciones de seguridad de su Navegador, para conocer mejor
        sus funciones.
    </p>

    <h3>13.2. ¿QUÉ COOKIES O TECNOLOGÍAS SIMILARES UTILIZAMOS?</h3>
    <p>
        Analytics (regidas por las políticas de Google), para capturar información como duración en las Plataformas,
        cantidad de usuarios que ingresan a las mismas, navegación, entre otros. Facebook para que los usuarios puedan
        interactuar con el contenido de esta red social. Las condiciones de utilización de estas cookies y la
        información recopilada, se regula por las políticas de privacidad de la red social. Web storage” o “local
        storage”, tecnología similar a las cookies, utilizada para el almacenamiento de la información recolectada a
        través de las Plataformas.
    </p>

    <h3>13.3. ¿QUÉ INFORMACIÓN RECOLECTAMOS?</h3>
    <p>
        Básicamente, se recolecta la información del Usuario que está ingresado en la Plataforma, a qué Grupo de Interés
        pertenece, institución educativa, cursos, interacción en las Plataformas, intereses, entre otras.
    </p>

    <h3>13.4. ¿PARA QUÉ UTILIZAMOS ESA INFORMACIÓN?</h3>
    <p>
        Para facilitar la navegación de los Usuarios en nuestras Plataformas, generar contenidos de interés, así como
        potenciar espacios de desarrollo educativo en los diferentes niveles.
    </p>

    <h3>14. VIGENCIA</h3>
    <p>
        La presente Política, rige a partir del 28 de enero de 2021. Las Bases de Datos sujetas a Tratamiento por parte
        del Responsable del Tratamiento, estarán vigentes mientras subsistan las finalidades para las cuales se
        recolectaron los datos y/o el término que establezca la ley. El Responsable del Tratamiento se reserva el
        derecho de modificar en cualquier momento la presente Política de Privacidad. En caso de haber cambios
        sustanciales en el contenido de la misma, en relación con la identificación del Responsable del Tratamiento y a
        la finalidad del Tratamiento de los Datos personales, los cuales puedan afectar el contenido de la Autorización,
        el Responsable del Tratamiento comunicará estos cambios al Titular antes de o a más tardar al momento de
        implementar las nuevas políticas y requerirá una nueva autorización cuando el cambio se refiera a la finalidad
        del Tratamiento.
    </p>
</div>