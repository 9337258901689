<loading-layout #loadingLayout>
    <div *ngIf="horario">
        <div class="flex-container">
            <div class="element">
                <figure class="figure">
                    <img
                        [src]="horario.url"
                        class="figure-img img-fluid rounded img-horario"
                        style="height: 100%; width: 100%; object-fit: contain"
                        alt="A generic square placeholder image with rounded corners in a figure."
                    />
                </figure>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-sm-12">
            <div id="descripcion" class="card" [innerHTML]="horario?.descripcion"></div>
        </div>
    </div>
</loading-layout>
