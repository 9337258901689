import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_streaming.component.html"
})
export class HomeStreamingComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        this.menuItems = [
            {
                route: "/streaming/saber11",
                params: { tipo: "saber11" },
                label: "Streaming Saber 11",
                text: "Mira las transmisiones en vivo y videos de clases Saber 11",
                linkText: "Ver streaming Saber 11",
                icon: "camara-video"
            }
        ]
    }
}
