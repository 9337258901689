import { Component, HostBinding } from "@angular/core"
import { trigger, transition, useAnimation } from "@angular/animations"
import * as animations from "../custom-animations"

@Component({
    selector: "[anim-enter-from-right],anim-enter-from-right",
    template: "<ng-content></ng-content>",
    animations: [
        trigger("animSlideInFromRight", [
            transition(":enter", [
                useAnimation(animations.slideInHorizontalAnimation, {
                    params: {
                        timings: "0.3s 0ms ease-in",
                        from: "20px"
                    }
                })
            ])
        ])
    ]
})
export class AnimEnterFromRightComponent {
    @HostBinding("@animSlideInFromRight") state: string = "slideIn"
}
