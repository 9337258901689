<nav class="navbar">
    <div class="container-fluid">
        <div class="navbar-header">
            <div class="navbar-left navbar-logo-container">
                <div class="navbar-logo-container-inner">
                    <a routerLink="/home">
                        <img
                            class="navbar-logo"
                            src="{{ config.app.assets.logoMedium }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
        </div>
        <div class="clearfix user-menu">
            <ul class="nav navbar-top-links navbar-right">
                <li>
                    <a routerLink="/" role="button">Inicio</a>
                </li>
                <li>
                    <a routerLink="/public_blog" role="button">Blog</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<alerta-box></alerta-box>
