<ng-container *ngIf="!saved && form">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [disabled]="!!(usuario['usuario_' + pais]['cc'] || usuario['usuario_' + pais]['ti'])"
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['cc']"
                    [(value)]="usuario['usuario_' + pais]['cc'] || usuario['usuario_' + pais]['ti']"
                    [key]="'cc'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                ></form-input>
            </div>

            <div class="col-md-6">
                <h3>Datos Académicos</h3>
                <form-input
                    [form]="form"
                    [params]="params['egresado']"
                    [(value)]="usuario['egresado']"
                    [key]="'egresado'"
                ></form-input>

                <label [attr.for]="'colegio'">{{ params["colegio"].label }}</label>
                <autocomplete
                    [placeholder]="'Escribe y selecciona entre las opciones'"
                    [formControlName]="'colegio'"
                    [options]="filteredEstablecimientos"
                    (filterChange)="getEstablecimientos($event)"
                    (controlValue)="updateColegio($event)"
                    showTextFun="toStringWithIdPais"
                    class="autocomplete"
                ></autocomplete>

                <div [hidden]="usuario['egresado']">
                    <form-input
                        [form]="form"
                        [params]="params['nivel_id']"
                        [(value)]="usuario['nivel_id']"
                        [key]="'nivel_id'"
                        [withLabel]="false"
                    >
                        <label for="nivel_id" [attr.for]="'nivel_id'">
                            Grado
                            <span class="required-mark"></span>
                        </label>
                    </form-input>
                </div>

                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>
                <form-input
                    *ngIf="withPoliticasPrivacidad"
                    [form]="form"
                    [params]="params['politicas_privacidad']"
                    [(value)]="usuario['politicas_privacidad']"
                    [key]="'politicas_privacidad'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Actualizar</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos actualizado correctamente tus datos. Te redirigemos a la página principal en un instante.
</ng-container>
