<ll-titulo>Orientación</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <sub-menu [itemsMenu]="itemsMenu"></sub-menu>
    </div>
    <div class="col-md-4 col-sm-6">
        <blog-showcasebox></blog-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <carreras-showcasebox></carreras-showcasebox>
    </div>
</div>
