import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { AppConfig, I18nService } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { first, filter } from "rxjs/operators"
import { Subscription } from "rxjs"
import { Store, select } from "@ngrx/store"
import { State, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"

declare const config: AppConfig

@Component({
    selector: "tu-progreso",
    templateUrl: "./tu-progreso.component.html"
})
export class TuProgresoComponent extends MenuComponent implements OnInit {
    config: typeof config
    sub: Subscription
    tipoInstrumento: string

    asignaturas: Asignatura[]
    menuReady: boolean = false
    itemsMenu: any = []

    subAlias: Subscription
    evaluacionTipoAlias: string

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected route: ActivatedRoute,
        protected translatorService: I18nService,
        protected store: Store<State>,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"] || config.plataforma.evaluacionDefault
        })

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoInstrumento]
        })

        this.config = config
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.asignaturas = asignaturasByEvaluacionTipo["saber11"]

        this.itemsMenu = [
            {
                route: "/saber11/resultados",
                params: { tipo_instrumento: "saber11" },
                name: "Historial de " + this.evaluacionTipoAlias + " por asignatura"
            },
            {
                route: "/saber11/compartidos",
                params: { tipo_instrumento: "simulacro saber11" },
                name: "Historial de " + this.evaluacionTipoAlias
            },
            {
                route: "/saber11/estadisticas",
                params: { tipo_instrumento: "saber11" },
                name: "Estadísticas"
            },
            {
                route: "/saber11/mi_progreso",
                params: { tipo_instrumento: "saber11" },
                name: "Evolución de tu progreso"
            }
        ]
        this.menuReady = true
    }
}
