import { SharedLayoutComponent } from "@puntaje/platforms/pnco"
import {
    PlaceholderPageComponent,
    TermsAndConditionsComponent,
    InstruccionesEliminacionDatosComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LandingComponent } from "./landing.component"
import { HomeComponent } from "./home.component"

import { TokenGuard } from "@puntaje/puntaje/services"
import { PrivacidadProteccionDatosComponent } from "./privacidad-proteccion-datos/privacidad-proteccion-datos.component"

import { EstudiarComponent } from "./homes/estudiar/estudiar.component"
import { PracticarComponent } from "./homes/practicar/practicar.component"
import { TuProgresoComponent } from "./homes/tu-progreso/tu-progreso.component"
import { OrientacionComponent } from "./homes/orientacion/orientacion.component"
import { ParticipaComponent } from "./homes/participa/participa.component"
import { TermsAndConditionsColombiaComponent } from "@puntaje/puntaje/new-modules/landing"

export const LandingRoutes: Routes = [
    { path: "landing", component: LandingComponent },
    {
        path: "home",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    { path: "landing/home", redirectTo: "home", pathMatch: "full" },
    {
        path: "condicionesUso",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: TermsAndConditionsComponent,
                data: { component: TermsAndConditionsColombiaComponent }
            }
        ]
    },
    {
        path: "politicas_privacidad_y_proteccion_datos",
        component: SharedLayoutComponent,
        children: [{ path: "", component: PrivacidadProteccionDatosComponent }]
    },
    { path: "pronto", component: LoggedLayoutComponent, children: [{ path: "", component: PlaceholderPageComponent }] },
    {
        path: "instrucciones_eliminacion_datos",
        component: SharedLayoutComponent,
        children: [{ path: "", component: InstruccionesEliminacionDatosComponent }]
    },
    {
        path: "estudiar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstudiarComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "practicar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PracticarComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "tu_progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TuProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "orientacion",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: OrientacionComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "participa",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ParticipaComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const LandingRouting = RouterModule.forChild(LandingRoutes)
