import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    Establecimientos,
    Establecimiento,
    Lugar,
    Lugares
} from "@puntaje/puntaje/api-services"
import { AuthService, GenericModalComponent, SessionService } from "@puntaje/shared/core"

import { BaseRegisterCompletarComponent } from "./base_register_completar.component"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { BaseRegisterCompletarSocialComponent } from "./base_register_completar_social.component"
import { Clasificaciones, Clasificacion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "usuario-completar-registro-social-puntaje-colombia",
    templateUrl: "register_completar_social_puntaje_colombia.component.html",
    styleUrls: ["base_register_completar.component.scss"]
})
export class RegisterCompletarSocialPuntajeColombiaComponent extends BaseRegisterCompletarSocialComponent {
    withPoliticasPrivacidad: boolean = config.registro && config.registro.enablePoliticasPrivacidad

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        establecimientosService: Establecimientos,
        lugaresService: Lugares,
        clasificacionesService: Clasificaciones
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            establecimientosService,
            lugaresService,
            clasificacionesService
        )
    }

    getEstablecimientos(filter: string) {
        const params = {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: filter },
            lugar_tipo: { lugar_tipo: ["Municipio", "Departamento"] },
            include: "[establecimiento_colombia]"
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.filteredEstablecimientos = establecimientos
        })
    }
}
