<ng-container *ngIf="!saved">
    <form *ngIf="form" novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <div>
                    <div>
                        <label class="label-completado">Nombre</label>
                        <p class="valor-completado">{{ usuario.nombre }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Apellido Paterno</label>
                        <p class="valor-completado">{{ usuario.apellido_paterno }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Apellido Materno</label>
                        <p class="valor-completado">{{ usuario.apellido_materno | easyplaceholder: "-" }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Número de Identificación</label>
                        <p class="valor-completado">
                            {{ usuario["usuario_" + pais].cc || usuario["usuario_" + pais].ti }}
                        </p>
                    </div>
                    <div *ngIf="grupoUsuario?.establecimiento?.establecimiento">
                        <label class="label-completado">Establecimiento</label>
                        <p class="valor-completado">
                            {{ grupoUsuario?.establecimiento?.establecimiento | easyplaceholder: "-" }}
                        </p>
                    </div>
                    <div *ngIf="grupoUsuario?.nombre">
                        <label class="label-completado">Grado</label>
                        <p class="valor-completado">{{ grupoUsuario?.nombre }}</p>
                    </div>

                    <ng-container *ngIf="!grupoUsuario || (grupoUsuario && !grupoUsuario.establecimiento)">
                        <label [attr.for]="'lugarColegio'">Municipio de tu Establecimiento</label>
                        <autocomplete
                            [placeholder]="'Escribe y selecciona entre las opciones'"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="lugarColegio"
                            [options]="filteredLugarColegio"
                            (filterChange)="getLugarColegio($event)"
                            (controlValue)="updateLugarColegio($event)"
                            class="autocomplete"
                        ></autocomplete>
                        <label [attr.for]="'colegio'">
                            Establecimiento
                            <span class="required-mark"></span>
                        </label>
                        <autocomplete
                            [placeholder]="'Escribe y selecciona entre las opciones'"
                            [formControlName]="'colegio'"
                            [options]="filteredEstablecimientos"
                            (filterChange)="getEstablecimientos($event)"
                            (controlValue)="updateColegio($event)"
                            showTextFun="toStringWithIdPais"
                            class="autocomplete"
                            [disabled]="!lugarColegio"
                        ></autocomplete>
                        <form-input-error
                            [form]="form"
                            [params]="params['colegio']"
                            [value]="usuario['colegio']"
                            [key]="'colegio'"
                        ></form-input-error>

                        <form-input
                            [form]="form"
                            [params]="params['nivel_id']"
                            [(value)]="usuario['nivel_id']"
                            [key]="'nivel_id'"
                            [withLabel]="false"
                        >
                            <label for="nivel_id" [attr.for]="'nivel_id'">
                                Grado
                                <span class="required-mark"></span>
                            </label>
                        </form-input>
                        <br />
                    </ng-container>
                </div>

                <h3>Datos de la Cuenta</h3>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuario['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                    [withLabel]="false"
                >
                    <label for="telefono" [attr.for]="'telefono'">
                        Teléfono
                        <span class="required-mark"></span>
                    </label>
                </form-input>
            </div>

            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuario['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuario['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>
                <form-input
                    *ngIf="withPoliticasPrivacidad"
                    [form]="form"
                    [params]="params['politicas_privacidad']"
                    [(value)]="usuario['politicas_privacidad']"
                    [key]="'politicas_privacidad'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Actualizar</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved && mailChanged">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>

<ng-container *ngIf="saved && !mailChanged">
    Tus datos han sido actualizados correctamente. En unos segundos te redirigiremos a la página principal.
</ng-container>
