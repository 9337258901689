import { Component, Input } from "@angular/core"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { Usuarios } from "@puntaje/puntaje/api-services"

/*#################################

Template simple para páginas una vez que usuario se haya logeado. Incluye el navbar en la parte
superior.
Implementa una sección de contenido, un título, un subtítulo y un set de tabs (no son requeridos, pero título puede incluir
un valor por defecto si no está definido).

logged-layout-toolnavbar
logged-layout-titulo
logged-layout-subtitulo
logged-layout-contenido
logged-layout-tabs
logged-layout-topside
logged-layout-over-title

#################################*/

@Component({
    templateUrl: "logged_layout.component.html",
    selector: "logged-layout",
    styleUrls: ["logged_layout.scss"]
})
export class LoggedLayoutComponent {
    confirmedAccount: boolean = false
    @Input() freeContentStyle: boolean = false
    @Input() subtitle: string = ""

    constructor(
        public authService: AuthService,
        protected sessionService: SessionService,
        protected router: Router,
        public usuariosService: Usuarios
    ) {}

    ngOnInit() {
        this.confirmedAccount =
            this.sessionService.getConfirmedAt() != null && this.sessionService.getConfirmedAt() != "null"
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    confirmar() {
        this.usuariosService
            .requestConfirmarEmail()
            .then(response => this.router.navigate(["/usuarios/confirmar_email"]))
    }
}
