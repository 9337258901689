import { Component, HostBinding, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService, CapitalizePipe } from "@puntaje/shared/core"
import { CarreraSedes, CarreraSede } from "@puntaje/carreras/api-services"
import { CarrerasPonderaciones } from "../carreras-ponderaciones.service"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "carreras-showcasebox",
    templateUrl: "carreras-showcasebox.component.html",
    styleUrls: ["carreras-showcasebox.component.scss"]
})
export class CarrerasShowcaseBoxComponent {
    carreraSedes: CarreraSede[]
    capitalize = new CapitalizePipe()
    @HostBinding("class") innerClass = "showcasebox_style_1_carreras"

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected authService: AuthService,
        protected carreraSedesService: CarreraSedes,
        protected carrerasPonderaciones: CarrerasPonderaciones
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        const params: any = {
            include: "[carrera?,preferencia_carrera_sedes,sede:universidad,ponderaciones?:ponderable?]",
            preferenciaCarreraSede: {
                usuarioId: this.authService.getUserData().id
            },
            pais: {
                pais: this.capitalize.transform(config.plataforma.pais)
            },
            withLikesCarrera: 1,
            withLikesUniversidad: 1,
            sort_by: "PreferenciaCarreraSede.orden",
            order: "asc",
            per: 3
        }

        this.carreraSedesService.wherePost(params).then(carreraSedes => {
            this.carreraSedes = carreraSedes
            this.loadingLayout.ready()
        })

        this.carrerasPonderaciones.loadVariables()
    }
}
