import { PuntajeUniversidadModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

import { UniversidadModule } from "@puntaje/puntaje/new-modules/universidad"
import { universidadRoutes } from "./alumnos-universidad.routing"

@NgModule({
    declarations: [],
    imports: [CommonModule, AlumnosLayoutsModule, universidadRoutes, UniversidadModule, PuntajeUniversidadModule]
})
export class AlumnosUniversidadModule {}
