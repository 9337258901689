<ll-titulo>Desafio Saber 11</ll-titulo>

<loading-layout>
    <div *ngIf="!loadingLayout.loading" class="app-container">
        <div class="iphone">
            <div class="app">
                <iframe [src]="url" width="320" height="480" frameborder="0" marginheight="0" marginwidth="0"></iframe>
            </div>
        </div>
    </div>
</loading-layout>
