import { Component, Directive, ViewChild, AfterContentInit, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

/*#################################


#################################*/

@Component({
    templateUrl: "public-layout.component.html",
    selector: "public-layout",
    styleUrls: ["public-layout.scss"]
})
export class PublicLayoutComponent {
    @Input() freeContentStyle: boolean = false

    @Input() subtitle: string = ""

    constructor(public authService: AuthService) {}
}
