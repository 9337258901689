import { ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AuthService, S3, S3Service, GenericModalComponent } from "@puntaje/shared/core"
import {
    GrupoUsuarioUsuarios,
    Usuario,
    UsuarioAvatarEdit,
    UsuarioAvatarEditForm,
    UsuarioEditColombia,
    UsuarioEditColombiaForm,
    Usuarios
} from "@puntaje/puntaje/api-services"
import { selectAsignaturasList, State, CrearAlertaLogros } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"
import { CheckAchievementAsync, CheckActividadAsync } from "@puntaje/shared/core"
import { UsuarioEmailModalComponent } from "./usuario-email-modal/usuario-email-modal.component"

@Component({
    selector: "usuarios-edit-colombia",
    templateUrl: "usuarios.edit.component.html",
    styleUrls: ["usuarios.edit.component.scss"]
})
export class UsuariosEditColombiaComponent implements OnInit {
    usuario: Usuario
    usuarioEdit: UsuarioEditColombia
    usuarioAvatarEdit: UsuarioAvatarEdit
    oUsuarioEdit: UsuarioEditColombia
    oUsuarioAvatarEdit: UsuarioAvatarEdit
    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    idPaisAliases: any = {}
    idPaisAliasMap: { [key: string]: string } = config.plataforma.identificadorUsuarioAlias
    @Input() enableShowCountryData: boolean = false
    @Input() enableFacebookLink: boolean = true
    @Input() enableTutorias: boolean = true
    @Input() enableAsignaturaDefinida: boolean = true
    @Input() enableDeleteMe: boolean = false
    @Input() enableDeleteMeWithGrupoUsuarios = true

    userHasGrupoUsuarios: boolean
    paramsAvatar = UsuarioAvatarEditForm.formParams
    params = UsuarioEditColombiaForm.formParams

    @Input() disableViewNivel = false
    @Input() disableViewLugar = false

    @Input() disableEditNombres: boolean = false
    @Input() disableEditNivel: boolean = false
    @Input() disableEditLugar = false

    @Input() usuarioId: number

    form: UntypedFormGroup
    formAvatar: UntypedFormGroup

    edit_user_info: boolean = false
    edit_avatar: boolean = false
    edit_password: boolean = false
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutAvatar") loadingLayoutAvatar: LoadingLayoutComponent
    @ViewChild("cuentaEliminadaModal") cuentaEliminadaModal: GenericModalComponent
    @ViewChild(UsuarioEmailModalComponent, { static: true }) usuarioEmailModalComponent: UsuarioEmailModalComponent
    loadingAvatar: boolean = false
    lugarLabel: string

    @Input() enableTutor: boolean = false

    edit_user_asignatura: boolean = false
    asignaturas: Asignatura[]
    asignatura_id: number
    asignatura: Asignatura
    curso: string
    deleteInput: string
    plataforma: string

    constructor(
        protected authService: AuthService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        public s3Service: S3Service,
        protected injector: Injector,
        protected store: Store<State>,
        protected clasificacionesService: Clasificaciones,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios
    ) {
        this.lugarLabel = config.plataforma.lugarLabel ? config.plataforma.lugarLabel : null
        this.idPais.forEach(id => {
            this.idPaisAliases[id] = config.plataforma?.identificadorUsuarioAlias?.[id] || id
        })
    }

    ngOnInit() {
        this.plataforma = config.plataforma.name
        this.enableDeleteMe = this.enableDeleteMe ? this.enableDeleteMe : false
        this.obtenerAsignaturas()

        this.usuariosService.find(this.usuarioId, { include: "usuario_" + this.pais }).then((usuario: Usuario) => {
            this.usuario = usuario
            this.usuarioEdit = new UsuarioEditColombia(usuario.id)
            this.usuarioEdit.fromUsuario(usuario)
            this.oUsuarioEdit = this.usuarioEdit.clone()
            this.form = UsuarioEditColombiaForm.getForm(this.usuarioEdit, null, this.injector)

            this.usuarioAvatarEdit = new UsuarioAvatarEdit(usuario.id)
            this.usuarioAvatarEdit.fromUsuario(usuario)
            this.oUsuarioAvatarEdit = this.usuarioAvatarEdit.clone()
            this.formAvatar = UsuarioAvatarEditForm.getForm(this.usuarioAvatarEdit)
            if (usuario.asignatura_id) {
                this.asignatura = this.asignaturas.filter(d => d.id == usuario.asignatura_id)[0]
            }
            this.clasificacionesService
                .where({ clasificacion: { id: this.usuario.nivel_id } })
                .then((clasificaciones: Clasificacion[]) => {
                    if (clasificaciones.length > 0) this.curso = clasificaciones[0].clasificacion
                })

            this.checkIfUserHasGrupoUsuarios()

            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    saveProfileImage() {
        UsuarioAvatarEditForm.markFormControlsAsTouched(this.formAvatar)
        if (this.formAvatar.valid) {
            this.loadingLayoutAvatar.standby()
            this.s3Service.where(this.usuarioAvatarEdit.getS3Params()).then((policies: S3[]) => {
                let policy = policies as any as S3
                this.usuarioAvatarEdit["avatar"] = policy["key"]
                this.s3Service.uploadToS3(
                    policy,
                    this.usuarioAvatarEdit.file,
                    this.usuariosService.tableName,
                    this.updateUsuario.bind(this)
                )
            })
        }
    }

    edit_asignatura() {
        this.edit_user_asignatura = !this.edit_user_asignatura
    }

    @CheckAchievementAsync(["PN_SUBIR_AVATAR", "PN_COMPLETAR_PERFIL"], CrearAlertaLogros)
    @CheckActividadAsync("SA")
    updateUsuario() {
        return this.usuariosService
            .update(this.usuarioAvatarEdit.usuario_id, this.usuarioAvatarEdit.toUsuario())
            .then((response: Usuario) => {
                this.authService.setUserData(response)
                this.usuario = response
                setTimeout(() => {
                    this.usuarioAvatarEdit["file"] = undefined
                    this.usuarioEdit["avatar"] = this.usuario.avatar
                    UsuarioEditColombiaForm.markFormControlsAsPristine(this.formAvatar)
                    UsuarioEditColombiaForm.markFormControlsAsUntouched(this.formAvatar)
                    this.cdr.detectChanges()
                    this.loadingAvatar = true
                }, 150)
                return response
            })
    }

    onLoadProfileImage() {
        if (this.loadingAvatar) {
            this.loadingLayoutAvatar.ready()
            this.loadingAvatar = false
        }
    }

    save() {
        UsuarioEditColombiaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.saveAll()
        }
    }

    saveAll() {
        this.usuariosService.update(this.usuarioEdit.id, this.usuarioEdit.toUsuario()).then(response => {
            if (!this.form.controls["email"].pristine) {
                this.usuarioEmailModalComponent.open()
            }
            this.authService.setUserData(response)
            this.router.navigate(["usuarios/" + this.usuarioEdit.id])
        })
    }

    clear() {
        setTimeout(() => {
            this.usuarioEdit = this.oUsuarioEdit.clone()
            UsuarioEditColombiaForm.markFormControlsAsPristine(this.form)
            UsuarioEditColombiaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    toggleUserInfo(event) {
        event.stopPropagation()
        this.edit_user_info = !this.edit_user_info
    }

    toggleAvatarEdit(event) {
        ;(document.getElementById("avatarInput").getElementsByClassName("inputfile-label")[0] as HTMLElement).click()
        event.stopPropagation()
    }

    cancelNewAvatar() {
        this.usuarioAvatarEdit["file"] = undefined
    }

    showEditPassword() {
        this.edit_password ? (this.edit_password = false) : (this.edit_password = true)
    }

    async obtenerAsignaturas() {
        this.asignaturas = []
        this.asignaturas = await this.store
            .pipe(
                select(selectAsignaturasList),
                filter(x => !!x),
                first()
            )
            .toPromise()
    }

    saveAsignatura() {
        const usuario = new Usuario(true)
        usuario.asignatura_id = this.asignatura?.id || null

        this.usuariosService.update(this.usuarioEdit.id, usuario).then(u => {
            this.authService.setUserData(u)
            this.router.navigate(["usuarios/" + this.usuarioEdit.id])
        })
    }

    setAsignatura(asignatura) {
        this.asignatura = asignatura
    }

    checkIfUserHasGrupoUsuarios() {
        this.grupoUsuarioUsuariosService
            .where({ grupo_usuario_usuario: { usuario_id: this.usuarioId }, per: 1 })
            .then(grupoUsuarios => {
                this.userHasGrupoUsuarios = grupoUsuarios.length > 0
            })
    }

    eliminarCuenta() {
        this.usuariosService.remove(this.authService.getUserData().id).then(u => {
            this.cuentaEliminadaModal.buttonPressed()
            this.authService.logout()
            this.router.navigate(["/landing"])
        })
    }
}
