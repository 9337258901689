import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuarios, PremiumUsuarios, PremiumUsuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "tiempo-pro",
    templateUrl: "./tiempo_pro.component.html",
    styleUrls: ["tiempo_pro.component.scss"]
})
export class TiempoProComponent implements OnInit {
    config: typeof config
    // @Input() pagina: string = "home";
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    show_message: boolean = false
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private authService: AuthService,
        protected sessionService: SessionService,
        protected premiumUsuariosService: PremiumUsuarios
    ) {
        this.config = config
    }

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        this.show_message = this.authService.showLoginMessage()
        this.checkDateInterval()
    }

    checkDateInterval() {
        if (!this.authService.getUserData()) return

        let currentDate = new Date()
        let usuarioId = this.authService.getUserData().id
        let today = new Date().toISOString().slice(0, 10)
        let cosa = 1000 * 60 * 60 * 24
        this.premiumUsuariosService
            .where({
                premium_usuario: {
                    usuario_id: usuarioId,
                    activo: 1,
                    gte: { fecha_termino: today },
                    lte: { fecha_inicio: today }
                }
            })
            .then((response: PremiumUsuario[]) => {
                let pu = response
                if (pu.length > 0) {
                    let fecha_termino = new Date(pu[0].fecha_termino)
                    let diff = Math.abs(fecha_termino.getTime() - currentDate.getTime())
                    let dias_faltantes_totales = Math.round(diff / cosa)
                    if (dias_faltantes_totales < 10) {
                        this.dias = true
                        this.dias_faltantes = dias_faltantes_totales
                        if (dias_faltantes_totales != 0) {
                            this.frase = "Te quedan " + this.dias_faltantes + " días de Puntaje Pro."
                        } else if (dias_faltantes_totales == 0) {
                            this.frase = "Es tu último día de Puntaje Pro."
                        }
                    }
                }
            })
    }
}
