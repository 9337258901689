import { PuntajeLandingModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"
import { LazyLoadImageModule } from "ng-lazyload-image"
import { JoyrideModule } from "ngx-joyride"

import { HomeComponent } from "./home.component"
import { LandingComponent } from "./landing.component"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { SharedLayoutsModule } from "@puntaje/platforms/pnco"
import { LandingRouting } from "./alumnos_landing.routing"

import { LandingModule } from "@puntaje/puntaje/new-modules/landing"
import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { StreamingModule } from "@puntaje/puntaje/new-modules/streaming"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"
import { LogrosModule } from "@puntaje/puntaje/new-modules/logros"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { EntrenadorModule } from "@puntaje/puntaje/new-modules/entrenador"

import { PrivacidadProteccionDatosComponent } from "./privacidad-proteccion-datos/privacidad-proteccion-datos.component"

import { EstudiarComponent } from "./homes/estudiar/estudiar.component"
import { PracticarComponent } from "./homes/practicar/practicar.component"
import { TuProgresoComponent } from "./homes/tu-progreso/tu-progreso.component"
import { OrientacionComponent } from "./homes/orientacion/orientacion.component"
import { ParticipaComponent } from "./homes/participa/participa.component"
import { LayoutsModule } from "@puntaje/puntaje/new-modules/layouts"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { BlogModule } from "@puntaje/puntaje/new-modules/blog"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CarrerasModule } from "@puntaje/puntaje/new-modules/carreras"

@NgModule({
    imports: [
        InstrumentosModule,
        CommonModule,
        FormsModule,
        LandingRouting,
        AlumnosLayoutsModule,
        LandingModule,
        UsuariosModule,
        UtilModule,
        Angular2FontawesomeModule,
        SharedLayoutsModule,
        MaterialesModule,
        NotificacionesModule,
        EnsayosModule,
        StreamingModule,
        PlanEstudiosModule,
        BannersModule,
        LazyLoadImageModule,
        DudasModule,
        NoticiasModule,
        EntrenadorModule,
        LogrosModule,
        LayoutsModule,
        GrupoUsuariosModule,
        EstadisticasModule,
        BlogModule,
        SharedModule,
        CarrerasModule,
        JoyrideModule.forRoot(),
        CUILayoutsModule,
        PuntajeLandingModule
    ],
    declarations: [
        HomeComponent,
        LandingComponent,
        PrivacidadProteccionDatosComponent,
        EstudiarComponent,
        PracticarComponent,
        TuProgresoComponent,
        OrientacionComponent,
        ParticipaComponent
    ],
    exports: [HomeComponent, LandingComponent]
})
export class AlumnosLandingModule {
    static forRoot(): ModuleWithProviders<AlumnosLandingModule> {
        return {
            ngModule: AlumnosLandingModule,
            providers: []
        }
    }
}
