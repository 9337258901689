import { EvaluationLayoutService } from "@puntaje/shared/cui"
import { Component, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { EvaluacionSecuencialComponent, PreguntasEnsayoComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { AuthService } from "@puntaje/shared/core"
import { Evaluacion, EvaluacionTiempo } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { RealizarEnsayoService } from "@puntaje/puntaje/new-modules/ensayos"
import { Store } from "@ngrx/store"
import {
    SetSeeingIndex,
    SetRespuestasActual,
    ResetDudaPreguntas,
    SetItems,
    SetActual,
    State
} from "@puntaje/puntaje/store"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "realizar_ensayo_secuencial.component.html",
    styleUrls: ["realizar_ensayo_secuencial.component.scss"]
})
export class RealizarEnsayoSecuencialComponent {
    evaluacion: Evaluacion
    evaluacionId: number
    showPreguntasMenu: boolean = false
    loadClock: boolean = false
    evaluacionTiempo: EvaluacionTiempo
    tipoEvaluacion: string
    tipoEvaluacionFromEvaluacion: string

    sub: Subscription
    subQuery: Subscription
    readyEvaluacionSub: Subscription
    showPreguntasMenuChangeSub: Subscription
    loadClockChangeSub: Subscription

    nombreEvaluacion: string = ""

    showTimeup: boolean = false
    hideProgreso: boolean = false

    @ViewChild(EvaluacionSecuencialComponent, { static: false }) evaluacionSecuencial: EvaluacionSecuencialComponent
    @ViewChild("preguntasEnsayo", { static: true }) preguntasEnsayo: PreguntasEnsayoComponent
    // onSelectPregunta: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected realizarEnsayoService: RealizarEnsayoService,
        protected store: Store<State>,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected evaluationLayoutService: EvaluationLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.realizar_ensayo_secuencial")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionId = +params["id"]

            this.store.dispatch(new SetActual({ actual: null }))
            this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
            this.store.dispatch(new SetSeeingIndex(0))
        })

        this.evaluationLayoutService.setOptions({ singleColumn: true })
    }

    onTimeup() {
        this.showTimeup = true
        this.hideProgreso = true
        setTimeout(() => {
            this.showTimeup = false
            this.evaluacionSecuencial.entregarEnsayo()
        }, 6000)
    }

    onReadyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
    }

    onLoadClockChange(loadClock) {
        this.loadClock = loadClock
    }

    onEvaluacionEntregada = evaluacionInstancia => {
        this.hideProgreso = false

        this.evaluacionId = null
        this.router.navigate(["/ensayo_instancias", evaluacionInstancia.id])

        this.resetStore()
    }

    onTiempoReady = function (ensayoTiempo) {
        this.evaluacionTiempo = ensayoTiempo
        this.cdr.detectChanges()
        this.evaluacionSecuencial.getRespuestasFromLocal()
        this.evaluacionSecuencial.displayPreguntas = true
    }

    resetStore() {
        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetItems({ items: [] }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new ResetDudaPreguntas())
        this.store.dispatch(new SetSeeingIndex(0))
    }

    // ngOnDestroy() {
    //     this.sub.unsubscribe()
    //     this.subQuery.unsubscribe()
    //     this.readyEvaluacionSub.unsubscribe()
    //     this.showPreguntasMenuChangeSub.unsubscribe()
    //     this.loadClockChangeSub.unsubscribe()
    // }
}
