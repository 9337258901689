import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { YoutubeVideoView } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "desafio.component.html",
    styleUrls: ["desafio.component.scss"]
})
export class DesafioComponent implements OnInit, OnDestroy {
    sub: Subscription
    desafio: string
    desafioData: any
    nombreDesafio: string = ""
    pagina: string
    evaluacionId: number
    desafios: any[] = [] //Esto debería estar por BD de alguna forma
    showVideo: boolean = false

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected router: Router) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.desafios.show")
        this.sub = this.route.params.subscribe(params => {
            this.desafio = params["desafio"]
            this.evaluacionId = +params["evaluacion_id"]
            this.desafioData = this.desafios.find(d => d.url === this.desafio)
            this.nombreDesafio = this.desafioData.desafio
            this.pagina = this.desafioData.pagina
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    toggleVideo() {
        this.showVideo = !this.showVideo
    }
}
