<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/home']" role="button">Inicio</a>
            </li>
            <li routerLinkActive="active-menu">
                <a
                    [routerLink]="['/estudiar']"
                    role="button"
                    joyrideStep="estudiar"
                    [text]="'tour.topmenu.estudiar' | t"
                >
                    {{ "topmenu.estudiar" | t }}
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a
                    [routerLink]="['/practicar']"
                    role="button"
                    joyrideStep="practicar"
                    [text]="'tour.topmenu.practicar' | t"
                >
                    {{ "topmenu.practicar" | t }}
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a
                    [routerLink]="['/tu_progreso']"
                    role="button"
                    joyrideStep="tu_progreso"
                    [text]="'tour.topmenu.tu_progreso' | t"
                >
                    {{ "topmenu.tu_progreso" | t }}
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a
                    [routerLink]="['/orientacion']"
                    role="button"
                    joyrideStep="orientacion"
                    [text]="'tour.topmenu.orientacion' | t"
                >
                    {{ "topmenu.orientacion" | t }}
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a
                    [routerLink]="['/participa']"
                    role="button"
                    joyrideStep="participa"
                    [text]="'tour.topmenu.participa' | t"
                >
                    {{ "topmenu.participa" | t }}
                </a>
            </li>
        </ul>
    </div>
</nav>
