import { PlanClasesDeAlumnosComponent, PlanClaseAlumnoComponent, ClasePlanClaseComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { TokenGuard } from "@puntaje/puntaje/services"
import { AppConfig, AuthGuard } from "@puntaje/shared/core"

import { HomePlanesEstudiosComponent } from "./home_planes_estudios.component"
declare const config: AppConfig

export const routes: Routes = [
    {
        path: "planes_estudio",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    get evaluacionTipos() {
                        return (config.plataforma.evaluacionTipos = ["saber11", "Examen UNAL"])
                    }
                }
            }
        ]
    },
    {
        path: "asignatura/:asignatura_id/planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesDeAlumnosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "plan_estudios2/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ClasePlanClaseComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "plan_estudios2/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_estudios",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: HomePlanesEstudiosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
