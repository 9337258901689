import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Store } from "@ngrx/store"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { State } from "@puntaje/puntaje/store"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { SessionService, AuthService } from "@puntaje/shared/core"
import { JoyrideService } from "ngx-joyride"
import { first } from "rxjs/operators"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent extends MenuComponent implements OnInit {
    config: typeof config
    usuario_id: string
    @Input() pagina: string = "home"
    hasGrupoUsuarios: boolean
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    linkEvaluacion: any = ["/saber11", "generar_simulacro"]
    asignaturas: Asignatura[]
    menuReady: boolean = false
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = false

    itemsMenu: any = []

    tour = {
        actividades: { step: "actividades", text: this.translatorService.translate("tour.home.actividades", {}) }
    }

    constructor(
        protected store: Store<State>,
        protected router: Router,
        protected route: ActivatedRoute,
        private sessionService: SessionService,
        protected translatorService: I18nService,
        protected joyrideService: JoyrideService,
        protected loggedLayoutService: LoggedLayoutService,
        private authService: AuthService
    ) {
        super(store)
        this.config = config
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
    }

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        this.setMenuItems()
        this.hasGrupoUsuarios = this.sessionService.getGrupoUsuariosActivos().length > 0

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.asignaturas = asignaturasByEvaluacionTipo["saber11"]

        this.itemsMenu = [
            {
                name: "Soy nuev@",
                fn: this.startTour.bind(this)
            },
            {
                route: "/saber11/generar_simulacro/" + asignaturasByEvaluacionTipo["saber11"][0].id,
                params: { tipo_instrumento: "saber11" },
                name: "Hacer Simulacro Saber 11° por asignatura"
            },
            {
                name: "Clases en Vivo",
                params: { tipo: "saber11" },
                route: "/streaming/saber11"
            },
            {
                name: "Planes de estudio",
                route: "/planes_estudio"
            },
            {
                name: this.translatorService.translate("home.itemsmenu.logros", {}),
                params: { tab: "logros" },
                route: "/usuarios/" + this.usuario_id
            }
        ]
        this.linkEvaluacion = ["/saber11", "generar_simulacro", asignaturasByEvaluacionTipo["saber11"][0].id]
        this.menuReady = true
    }

    startTour() {
        if (!this.joyrideService.isTourInProgress()) {
            this.joyrideService.startTour({
                steps: ["actividades", "estudiar", "practicar", "tu_progreso", "orientacion", "participa"],
                showCounter: false
            })
        }
    }
}
