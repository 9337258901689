import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { DesafioComponent, RealizarEnsayoComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "desafios/:desafio/:evaluacion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: DesafioComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "desafios/:desafio/:evaluacion_id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
